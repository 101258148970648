import { NotDone } from 'components'

const SupplierIntegrationAmazon: React.FC = () => {
  return (
    <>
      <NotDone />
    </>
  )
}

export { SupplierIntegrationAmazon }
