// React
import { useState } from 'react'
// Routing
import { Link } from 'react-router-dom'
// MainStem - UI
import {
  Button,
  FormattedCurrency,
  FormattedDateTime,
  FormattedNumber,
  InputDateTime,
  InputNumber,
  InputText,
  SelectSingle,
  Tabs,
  Tab,
  theme
} from '@mainstem/react-mainstem'
// DEPRECATE : Reactstrap
import { Card, CardBody, Col, Media, Row, Label, FormGroup } from 'reactstrap'
// DEPRECATE : Classnames
import classNames from 'classnames'
// DEPRECATE : MainStem - UI
import { ModalConfirm, EventBus } from '@mainstem/mainstem-react-app'
// Icons
import { faCalculator } from '@fortawesome/pro-light-svg-icons'
// MainStem - API
import { Api, MainStemAPIControllersAdminInvoicesUpdateAPIRequest } from 'api-new'
// Global - Components
import { Expandable } from 'components'
import { ShipmentTrackingInvoiceList } from 'components/ShipmentTrackingInvoiceList'
import { NotesList } from 'components/NotesList'
import InvoiceProblems from 'components/InvoiceProblems'
// Parent - Components
import InvoicedLineItems from '../InvoicedLineItems'
import { SmallBadge, FaIcon, PaddedSpan } from '../StyledComponents'

const FulfillmentExpandable = ({ fulfillmentMethod, lineItemsToBeInvoiced }) => {
  // API
  const mainStemApi = new Api()
  // Loading Indicators
  const [loading, setLoading] = useState(false)
  // Mocals
  const [showConfirmDeleteFulfillment, setShowConfirmDeleteFulfillment] = useState(false)
  const [showConfirmTaxRecalculation, setShowConfirmTaxRecalcution] = useState(false)
  const [showConfirmProcessSupplierIntegration, setShowConfirmProcessSupplierIntegration] = useState(false)
  const [totalIssues, setTotalIssues] = useState(0)
  const [editableFulfillment, setEditableFulfillment] = useState({
    ...fulfillmentMethod,
    editable: false
  })
  const importStatusOptions = [
    { value: 0, label: 'Open' },
    { value: 1, label: 'Partial' },
    { value: 2, label: 'Closed' },
    { value: 3, label: 'Ignore' }
  ]

  const [selectedImportStatus, setSelectedImportStatus] = useState(
    importStatusOptions.find((o) => o.label === fulfillmentMethod.internalAccounting.invoiceState)
  )

  const handleIntegrationsProcess = () => {
    setLoading(true)

    const apiRequest = {
      orderID: fulfillmentMethod.orderID
    }

    mainStemApi.api
      .invoicesIntegrationsProcess(apiRequest)
      .then(() => {
        setShowConfirmProcessSupplierIntegration(false)
        EventBus.dispatch(window.parent.document, 'getOrderDetails', null)
      })
      .finally(() => {
        setLoading(false)
      })
  }

  const handleCalculateSalesTax = () => {
    setLoading(true)

    const apiRequest = {
      orderID: fulfillmentMethod.orderID
    }

    mainStemApi.api
      .ordersCalculateSalesTax(apiRequest)
      .then(() => {
        setShowConfirmTaxRecalcution(false)
        EventBus.dispatch(window.parent.document, 'getOrderDetails', null)
      })
      .finally(() => {
        setLoading(false)
      })
  }

  const handleInvoiceDelete = () => {
    setLoading(true)

    const apiRequest = {
      invoiceID: fulfillmentMethod.id
    }

    mainStemApi.api
      .ordersInvoicesDelete(apiRequest)
      .then(() => {
        setShowConfirmDeleteFulfillment(false)
        EventBus.dispatch(window.parent.document, 'getOrderDetails', null)
      })
      .finally(() => {
        setLoading(false)
      })
  }

  const handleSave = () => {
    setLoading(true)

    const apiRequest = {
      invoiceID: fulfillmentMethod.id,
      dateNotified: editableFulfillment.dateNotified,
      dateReceived: editableFulfillment.dateReceived,
      dateShipped: editableFulfillment.dateShipped,
      supplierInvoiceID: editableFulfillment?.supplierIntegration?.integrationInvoiceID,
      qbPurchaseOrderID: editableFulfillment.internalAccounting?.purchaseOrderId,
      qbInvoiceID: editableFulfillment.internalAccounting?.invoiceID,
      qbImportState: selectedImportStatus?.value,
      shipmentCost: editableFulfillment.shipping,
      salesTax: editableFulfillment.salesTax,
      supplierSalesTax: editableFulfillment.supplierSalesTax,
      supplierShippingCost: editableFulfillment.supplierShippingCost
    } as MainStemAPIControllersAdminInvoicesUpdateAPIRequest

    mainStemApi.api
      .invoicesUpdate(apiRequest)
      .then(() => {
        const editableCopy = { ...editableFulfillment }
        editableCopy.editable = false
        setEditableFulfillment(editableCopy)
        EventBus.dispatch(window.parent.document, 'getOrderDetails', null)
      })
      .finally(() => {
        setLoading(false)
      })
  }

  const fulfillmentTotal = fulfillmentMethod.salesTax + fulfillmentMethod.shipping + fulfillmentMethod.subTotal

  const determineInvoiceAccountingIntegration = (internalAccounting) => {
    let elems = (
      <>
        <SmallBadge color='soft-danger'>Not Found</SmallBadge>
      </>
    )
    switch (internalAccounting.invoiceState) {
      case 'Ignore':
        elems = (
          <>
            <SmallBadge color='soft-danger'>Ingored</SmallBadge>
          </>
        )
        break
      case 'Open':
        elems = (
          <>
            {internalAccounting.purchaseOrderId ? (
              <SmallBadge color='soft-success'>Purchase Order Imported</SmallBadge>
            ) : (
              <SmallBadge color='soft-warning'>Purchase Order Waiting</SmallBadge>
            )}
            <br />
            {internalAccounting.invoiceId ? (
              <SmallBadge color='soft-success'>Invoice Imported</SmallBadge>
            ) : (
              <SmallBadge color='soft-warning'>Invoice Waiting</SmallBadge>
            )}
          </>
        )
        break
      case 'Complete':
        elems = (
          <>
            {internalAccounting.purchaseOrderId ? (
              <SmallBadge color='soft-success'>Purchase Order Imported</SmallBadge>
            ) : (
              <SmallBadge color='soft-danger'>Purchase Order Not Imported</SmallBadge>
            )}
            <br />
            {internalAccounting.invoiceId ? (
              <SmallBadge color='soft-success'>Invoice Imported</SmallBadge>
            ) : (
              <SmallBadge color='soft-danger'>Invoice Not Imported</SmallBadge>
            )}
          </>
        )
        break
      default:
        break
    }

    return elems
  }

  return (
    <>
      <Expandable
        actions={<> </>}
        header={
          <>
            <Row>
              <Col className='text-left align-self-center'>
                <Media
                  left
                  object
                  src={
                    fulfillmentMethod.name && fulfillmentMethod.logoURL
                      ? fulfillmentMethod.logoURL
                      : theme.images.notAvailable
                  }
                  style={{ maxHeight: '40px', maxWidth: '100%' }}
                />
                <PaddedSpan>
                  {fulfillmentMethod.isPrivateSupplier ? (
                    <SmallBadge color='soft-danger'>Private</SmallBadge>
                  ) : (
                    <SmallBadge color='soft-success'>Verified</SmallBadge>
                  )}
                </PaddedSpan>
                <div>
                  {fulfillmentMethod.supplierID ? (
                    <Link to={`/suppliers/details/${fulfillmentMethod.supplierID}`}>
                      {`${fulfillmentMethod.supplierID} - ${fulfillmentMethod.name}`}
                    </Link>
                  ) : (
                    <>{fulfillmentMethod.name}</>
                  )}
                </div>
                {/* <h4>Fulfilled By : {fulfillmentMethod.name ? fulfillmentMethod.name : 'MainStem'}</h4> */}
                <h5>Invoice ID: {fulfillmentMethod.id}</h5>
              </Col>
              {lineItemsToBeInvoiced.length > 0 && (
                <Col className='text-center align-self-center' md='auto'>
                  Open Requested Items
                  <br />
                  <FormattedNumber value={lineItemsToBeInvoiced.length} />{' '}
                  <small>
                    line item to fulfill. Total quantity of{' '}
                    {lineItemsToBeInvoiced
                      .map((i) => {
                        return i.quantityToBeInvoiced
                      })
                      .reduce((a, b) => a + b, 0)}
                  </small>
                </Col>
              )}
              <Col className='text-center align-self-center' md='auto'>
                Quantity Total
                <br />
                <FormattedNumber
                  value={fulfillmentMethod.lineItems
                    .map((i) => {
                      return i.quantity
                    })
                    .reduce((a, b) => a + b, 0)}
                />
              </Col>
              <Col className='text-center align-self-center' md='auto'>
                Unique Items
                <br />
                <FormattedNumber value={fulfillmentMethod.lineItems.length} />
              </Col>
              <Col className='text-center align-self-center' md='auto'>
                Total
                <br />
                <FormattedCurrency value={fulfillmentTotal} />
              </Col>
              <Col className='text-right align-self-center' md='auto'>
                {fulfillmentMethod.supplierIntegration ? (
                  <>
                    <small>Supplier Integration</small> &nbsp;
                    {fulfillmentMethod.supplierIntegration.integrationInvoiceID ? (
                      <SmallBadge color='soft-success'>
                        {fulfillmentMethod.supplierIntegration.integrationType
                          ? fulfillmentMethod.supplierIntegration.integrationType
                          : 'Unknown - Success'}
                      </SmallBadge>
                    ) : (
                      <SmallBadge color='soft-danger'>Not Applied/Notified</SmallBadge>
                    )}
                  </>
                ) : (
                  <>
                    <SmallBadge color='soft-danger'>Not Applied/Notified</SmallBadge>
                  </>
                )}
                <>
                  <br />
                  <small>Shipping Status</small>&nbsp;
                  {fulfillmentMethod.dateShipped ? (
                    <>
                      {fulfillmentMethod.dateReceived ? (
                        <>
                          <SmallBadge color='soft-success'>Received</SmallBadge>
                        </>
                      ) : (
                        <SmallBadge color='soft-success'>Shipped</SmallBadge>
                      )}
                    </>
                  ) : (
                    <SmallBadge color='soft-danger'>Not Shipped</SmallBadge>
                  )}
                </>
                {fulfillmentMethod.internalAccounting ? (
                  <>
                    <br />
                    <small>Internal Accounting</small>&nbsp;
                    {determineInvoiceAccountingIntegration(fulfillmentMethod.internalAccounting)}
                  </>
                ) : (
                  <>
                    <br />
                    <SmallBadge color='soft-danger'>Not Applied</SmallBadge>
                  </>
                )}
              </Col>
            </Row>
          </>
        }
        hideByDefault
      >
        <Card className={classNames('mb-3')}>
          <CardBody className='pt-0'>
            <>
              <PaddedSpan margin='5px 5px 5px 5px'>
                <Row>
                  <Col />
                  <Col md='auto'>
                    {editableFulfillment && editableFulfillment.editable ? (
                      <>
                        <Button
                          color='danger'
                          icon={theme.icons.delete}
                          loading={loading}
                          onClick={() => {
                            setShowConfirmDeleteFulfillment(true)
                          }}
                        >
                          Delete
                        </Button>
                        {' | '}
                        <Button
                          color='fancy'
                          icon={faCalculator}
                          loading={loading}
                          onClick={() => {
                            setShowConfirmTaxRecalcution(true)
                          }}
                        >
                          Tax Recalculation
                        </Button>
                        <PaddedSpan margin='5px 0px 0px 5px' />
                        <Button
                          color='fancy'
                          icon={theme.icons.mainstem.integrations}
                          loading={loading}
                          onClick={() => {
                            setShowConfirmProcessSupplierIntegration(true)
                          }}
                        >
                          Process Supplier Integration
                        </Button>
                        {' | '}
                        <Button
                          color='success'
                          icon={theme.icons.save}
                          loading={loading}
                          onClick={() => {
                            handleSave()
                          }}
                        >
                          <FaIcon size='1x' />
                          &nbsp; Save
                        </Button>
                        <PaddedSpan margin='5px 0px 0px 5px' />
                        <Button
                          color='danger'
                          icon={theme.icons.cancel}
                          onClick={() => {
                            let editableCopy = { ...editableFulfillment }
                            editableCopy = {
                              ...fulfillmentMethod
                            }
                            editableCopy.editable = false
                            setEditableFulfillment(editableCopy)
                          }}
                        >
                          Cancel
                        </Button>
                      </>
                    ) : (
                      <Button
                        icon={theme.icons.edit}
                        onClick={() => {
                          const editableCopy = { ...editableFulfillment }
                          editableCopy.editable = true
                          setEditableFulfillment(editableCopy)
                        }}
                      >
                        Edit
                      </Button>
                    )}
                  </Col>
                </Row>
              </PaddedSpan>
            </>
            <br />
            <Tabs>
              <Tab tabKey='details' title='Details'>
                <>
                  <Expandable
                    actions={<></>}
                    expanded={editableFulfillment && editableFulfillment.editable}
                    header={
                      <Row>
                        <Col className='text-left align-self-center'>Details</Col>
                        {(!editableFulfillment || (editableFulfillment && !editableFulfillment.editable)) && (
                          <>
                            <Col className='text-center align-self-center' md='auto'>
                              Date Shipped
                              <br />
                              {fulfillmentMethod.dateShipped ? (
                                <small>
                                  <FormattedDateTime datetime={fulfillmentMethod.dateShipped} />
                                </small>
                              ) : (
                                <SmallBadge color='soft-danger'>Not Shipped</SmallBadge>
                              )}
                            </Col>
                            <Col className='text-center align-self-center' md='auto'>
                              Date Recieved
                              <br />
                              {fulfillmentMethod.dateReceived ? (
                                <small>
                                  <FormattedDateTime datetime={fulfillmentMethod.dateReceived} />
                                </small>
                              ) : (
                                <SmallBadge color='soft-danger'>Not Received</SmallBadge>
                              )}
                            </Col>
                          </>
                        )}
                      </Row>
                    }
                    headerBody={
                      <>
                        {(!editableFulfillment || (editableFulfillment && !editableFulfillment.editable)) && (
                          <Row>
                            <Col md={12}>
                              <table className='table'>
                                <thead>
                                  <tr>
                                    <th className='text-center'>Sub Total</th>
                                    <th className='text-center'>Shipping</th>
                                    <th className='text-center'>Supplier Shipping</th>
                                    <th className='text-center'>Tax</th>
                                    <th className='text-center'>Supplier Tax</th>
                                    <th className='text-right'>Total</th>
                                  </tr>
                                </thead>

                                <tbody>
                                  <tr>
                                    <td className='text-center'>
                                      <FormattedCurrency value={fulfillmentMethod.subTotal} />
                                    </td>
                                    <td className='text-center'>
                                      <FormattedCurrency value={fulfillmentMethod.shipping} />
                                    </td>
                                    <td className='text-center'>
                                      <FormattedCurrency value={fulfillmentMethod.supplierShippingCost} />
                                    </td>
                                    <td className='text-center'>
                                      <FormattedCurrency value={fulfillmentMethod.salesTax} />
                                    </td>
                                    <td className='text-center'>
                                      <FormattedCurrency value={fulfillmentMethod.supplierSalesTax} />
                                    </td>
                                    <td className='text-right'>
                                      <strong>
                                        <FormattedCurrency
                                          value={
                                            fulfillmentMethod.subTotal +
                                            fulfillmentMethod.shipping +
                                            fulfillmentMethod.salesTax
                                          }
                                        />
                                      </strong>
                                    </td>
                                  </tr>
                                </tbody>
                              </table>
                            </Col>
                          </Row>
                        )}
                      </>
                    }
                    hideByDefault
                    hideShowHideButton
                  >
                    {editableFulfillment && (
                      <Row>
                        <Col sm={12}>
                          <Row>
                            <Col>
                              <Label>Date Shipped</Label>
                              <br />
                              <InputDateTime
                                isClearable
                                onChange={(date) => {
                                  console.log(date)
                                  const editableCopy = {
                                    ...editableFulfillment
                                  }
                                  editableCopy.dateShipped = date
                                  setEditableFulfillment(editableCopy)
                                }}
                                placeholder='Set Date Shipped'
                                value={
                                  editableFulfillment && editableFulfillment.dateShipped
                                    ? new Date(editableFulfillment.dateShipped)
                                    : null
                                }
                              />
                            </Col>
                            <Col>
                              <Label>Date Received</Label>
                              <br />
                              <InputDateTime
                                onChange={(date) => {
                                  console.log(date)
                                  const editableCopy = {
                                    ...editableFulfillment
                                  }
                                  editableCopy.dateReceived = date
                                  setEditableFulfillment(editableCopy)
                                }}
                                placeholder='Set Date Received'
                                value={
                                  editableFulfillment && editableFulfillment.dateReceived
                                    ? new Date(editableFulfillment.dateReceived)
                                    : null
                                }
                              />
                            </Col>
                          </Row>
                        </Col>

                        <Col sm={12}>
                          <table className='table'>
                            <thead>
                              <tr>
                                <th className='text-center'>Sub Total</th>
                                <th className='text-center'>Shipping</th>
                                <th className='text-center'>Supplier Shipping</th>
                                <th className='text-center'>Tax</th>
                                <th className='text-center'>Supplier Tax</th>
                                <th className='text-right'>Total</th>
                              </tr>
                            </thead>
                            <tbody>
                              <tr>
                                <td className='text-center'>
                                  <FormattedCurrency value={fulfillmentMethod.subTotal} />
                                </td>
                                <td className='text-center'>
                                  <InputNumber
                                    onChange={(newValue) => {
                                      setEditableFulfillment((previous) => {
                                        return {
                                          ...previous,
                                          shipping: newValue
                                        }
                                      })
                                    }}
                                    value={editableFulfillment.shipping}
                                  />
                                </td>
                                <td className='text-center'>
                                  <InputNumber
                                    onChange={(newValue) => {
                                      setEditableFulfillment((previous) => {
                                        return {
                                          ...previous,
                                          supplierShippingCost: newValue
                                        }
                                      })
                                    }}
                                    value={editableFulfillment.supplierShippingCost}
                                  />
                                </td>
                                <td className='text-center'>
                                  <InputNumber
                                    onChange={(newValue) => {
                                      setEditableFulfillment((previous) => {
                                        return {
                                          ...previous,
                                          salesTax: newValue
                                        }
                                      })
                                    }}
                                    value={editableFulfillment.salesTax}
                                  />
                                </td>
                                <td className='text-center'>
                                  <InputNumber
                                    onChange={(newValue) => {
                                      setEditableFulfillment((previous) => {
                                        return {
                                          ...previous,
                                          supplierSalesTax: newValue
                                        }
                                      })
                                    }}
                                    value={editableFulfillment.supplierSalesTax}
                                  />
                                </td>
                                <td className='text-right'>
                                  <strong>
                                    <FormattedCurrency
                                      value={
                                        editableFulfillment
                                          ? parseFloat(editableFulfillment.shipping) +
                                            parseFloat(editableFulfillment.salesTax) +
                                            fulfillmentMethod.subTotal
                                          : fulfillmentTotal
                                      }
                                    />
                                  </strong>
                                </td>
                              </tr>
                            </tbody>
                          </table>
                        </Col>
                      </Row>
                    )}
                  </Expandable>
                </>
              </Tab>
              <Tab length={fulfillmentMethod.lineItems.length} tabKey='items' title='Items'>
                <InvoicedLineItems
                  fulfillmentMethod={fulfillmentMethod}
                  lineItemsToBeInvoiced={lineItemsToBeInvoiced}
                />
              </Tab>
              <Tab length={fulfillmentMethod.shipments.length} tabKey='shipments' title='Shipments'>
                <ShipmentTrackingInvoiceList invoiceID={fulfillmentMethod.id} onActionApplied={() => {}} />
              </Tab>
              <Tab tabKey='notes' title='Notes'>
                <NotesList
                  allowPrivate
                  documentID={fulfillmentMethod.id}
                  documentType='fulfillment'
                  onActionApplied={() => {}}
                  title='Invoice Notes'
                />
              </Tab>
              <Tab length={totalIssues} tabKey='invoice problems' title='Invoice Problems'>
                <InvoiceProblems invoiceID={fulfillmentMethod.id} onReload={() => {}} setTotalIssues={setTotalIssues} />
              </Tab>
              <Tab tabKey='supplier' title='Supplier Integration'>
                <>
                  <Expandable
                    expanded={editableFulfillment.editable}
                    header={
                      <>
                        <Row>
                          <Col className='text-left align-self-center'>
                            {'Supplier Integration - '}
                            <Label color='soft-success'>
                              <small>type:</small>
                              {fulfillmentMethod.supplierIntegration.integrationType
                                ? fulfillmentMethod.supplierIntegration.integrationType
                                : 'Unknown'}
                            </Label>
                          </Col>
                          {!editableFulfillment.editable && (
                            <>
                              <Col className='text-center align-self-center' md='auto'>
                                Date Notified
                                <br />
                                {fulfillmentMethod.dateNotified ? (
                                  <small>
                                    <FormattedDateTime datetime={fulfillmentMethod.dateNotified} />
                                  </small>
                                ) : (
                                  <SmallBadge color='soft-danger'>Not Notified</SmallBadge>
                                )}
                              </Col>
                              <Col className='text-center align-self-center' md='auto'>
                                Supplier Invoice ID
                                <br />
                                {fulfillmentMethod.supplierIntegration.integrationInvoiceID ? (
                                  <>
                                    {fulfillmentMethod.supplierIntegration.integrationType === 'Amazon Business API' ? (
                                      <>
                                        <a
                                          href={`https://www.amazon.com/gp/your-account/order-details?orderID=${fulfillmentMethod.supplierIntegration.integrationInvoiceID}`}
                                          rel='noopener noreferrer'
                                          target='_blank'
                                        >
                                          <small>{fulfillmentMethod.supplierIntegration.integrationInvoiceID}</small>
                                        </a>
                                      </>
                                    ) : (
                                      <>
                                        <small>{fulfillmentMethod.supplierIntegration.integrationInvoiceID}</small>
                                      </>
                                    )}
                                  </>
                                ) : (
                                  <SmallBadge color='soft-danger'>Not Assigned</SmallBadge>
                                )}
                              </Col>
                            </>
                          )}
                        </Row>
                      </>
                    }
                    hideByDefault
                    hideShowHideButton
                  >
                    <Row>
                      <Col md={12}>
                        <Row>
                          <Col>
                            <Label>Date Notified</Label>
                            <br />
                            <InputDateTime
                              isClearable
                              onChange={(date) => {
                                console.log(date)
                                const editableCopy = {
                                  ...editableFulfillment
                                }
                                editableCopy.dateNotified = date
                                setEditableFulfillment(editableCopy)
                              }}
                              placeholder='Set Date Notified'
                              value={
                                editableFulfillment && editableFulfillment.dateNotified
                                  ? new Date(editableFulfillment.dateNotified)
                                  : null
                              }
                            />
                          </Col>
                          <Col>
                            <FormGroup>
                              <Label>Supplier Invoice ID</Label>
                              <InputText
                                onChange={(newValue) => {
                                  const editableCopy = {
                                    ...editableFulfillment
                                  }
                                  editableCopy.supplierIntegration.integrationInvoiceID = newValue
                                  setEditableFulfillment(editableCopy)
                                }}
                                value={editableFulfillment?.supplierIntegration?.integrationInvoiceID}
                              />
                            </FormGroup>
                          </Col>
                        </Row>
                      </Col>
                    </Row>
                  </Expandable>
                </>
              </Tab>
              <Tab tabKey='internal-accounting' title='Internal Accounting'>
                <>
                  <Expandable
                    actions={<></>}
                    expanded={editableFulfillment.editable}
                    header={
                      <>
                        <Row>
                          <Col className='text-left align-self-center'>
                            <>
                              {'Internal Accounting - '}
                              <Label color='soft-success'>
                                {!editableFulfillment.editable ? (
                                  <>
                                    <small>Import Status: </small>
                                    {fulfillmentMethod.internalAccounting.invoiceState}
                                  </>
                                ) : (
                                  <SelectSingle
                                    label='Import Status'
                                    onChange={(newValue) => {
                                      if (newValue) {
                                        setSelectedImportStatus(newValue)
                                      }
                                    }}
                                    options={importStatusOptions || []}
                                    placeholder='Select Import Status ...'
                                    value={selectedImportStatus}
                                  />
                                )}
                              </Label>
                            </>
                          </Col>
                          {!editableFulfillment.editable && (
                            <>
                              <Col className='text-center align-self-center' md='auto'>
                                Purchase Order ID
                                <br />
                                {fulfillmentMethod.internalAccounting.purchaseOrderId ? (
                                  <small>{fulfillmentMethod.internalAccounting.purchaseOrderId}</small>
                                ) : (
                                  <SmallBadge color='soft-danger'>Not Imported</SmallBadge>
                                )}
                              </Col>
                              <Col className='text-center align-self-center' md='auto'>
                                Invoice ID
                                <br />
                                {fulfillmentMethod.internalAccounting.invoiceId ? (
                                  <small>{fulfillmentMethod.internalAccounting.invoiceId}</small>
                                ) : (
                                  <SmallBadge color='soft-danger'>Not Imported</SmallBadge>
                                )}
                              </Col>
                            </>
                          )}
                        </Row>
                      </>
                    }
                    hideByDefault
                    hideShowHideButton
                  >
                    {editableFulfillment && (
                      <Row>
                        <Col sm={12}>
                          <Row>
                            <Col>
                              <FormGroup>
                                <Label>Purchase Order ID</Label>
                                <InputText
                                  onChange={(newValue) => {
                                    const editableCopy = {
                                      ...editableFulfillment
                                    }
                                    editableCopy.internalAccounting.purchaseOrderId = newValue
                                    setEditableFulfillment(editableCopy)
                                  }}
                                  value={editableFulfillment.internalAccounting.purchaseOrderId}
                                />
                              </FormGroup>
                            </Col>
                            <Col>
                              <FormGroup>
                                <Label>Invoice ID</Label>
                                <InputText
                                  onChange={(newValue) => {
                                    const editableCopy = {
                                      ...editableFulfillment
                                    }
                                    editableCopy.internalAccounting.invoiceID = newValue
                                    setEditableFulfillment(editableCopy)
                                  }}
                                  value={editableFulfillment.internalAccounting.invoiceId}
                                />
                              </FormGroup>
                            </Col>
                          </Row>
                        </Col>
                      </Row>
                    )}
                  </Expandable>
                </>
              </Tab>
            </Tabs>
          </CardBody>
        </Card>
      </Expandable>
      <ModalConfirm
        isOpen={showConfirmDeleteFulfillment}
        message={
          <>
            <p>This process will remove all invoiced line items as well.</p>
            <p>Any integration/accounting attached will be lost.</p>
          </>
        }
        onConfirmDenied={() => {
          setShowConfirmDeleteFulfillment(false)
        }}
        onConfirmSuccess={handleInvoiceDelete}
        title='Confirm Irreversible Fulfillment Deletion'
      />
      <ModalConfirm
        isOpen={showConfirmTaxRecalculation}
        message={
          <>
            <p>Recalculate the tax? This will occur for the entire order.</p>
          </>
        }
        onConfirmDenied={() => {
          setShowConfirmTaxRecalcution(false)
        }}
        onConfirmSuccess={handleCalculateSalesTax}
        title='Recalculate Sales Tax'
      />
      <ModalConfirm
        isOpen={showConfirmProcessSupplierIntegration}
        message={
          <>
            <p>Process the supplier integration for this invoice?</p>
          </>
        }
        onConfirmDenied={() => {
          setShowConfirmProcessSupplierIntegration(false)
        }}
        onConfirmSuccess={handleIntegrationsProcess}
        title='Process Supplier Integration'
      />
    </>
  )
}
export default FulfillmentExpandable
