// React
import { useEffect, useState } from 'react'
// MainStem - UI
import { Label, Loader, SelectSingle, useOnFirstLoad } from '@mainstem/react-mainstem'
// MainStem - UI - Types
import { ISelectOption } from '@mainstem/react-mainstem/dist/components/Form/Select/types'
// DEPRECATE : React Select
import AsyncSelect from 'react-select/async'
// MainStem - API
import { Api, MainStemAPIControllersAdminProductsParentListAPIRequest } from 'api-new'
// Local - Types
import { ISelectParentProduct } from './types'
import { de } from 'date-fns/locale'

const SelectParentProduct: React.FC<ISelectParentProduct> = ({
  defaultOption,
  defaultValue,
  onSelected
}: ISelectParentProduct) => {
  // API
  const mainStemApi = new Api()
  // Loading Indicators
  const [loading, setLoading] = useState<boolean>(false)
  // View State
  const [products, setProducts] = useState<ISelectOption[]>([])
  const [selectedProduct, setSelectedProduct] = useState<ISelectOption | undefined>(undefined)

  const loadData = (query) => {
    setLoading(true)

    const apiRequest = {
      filter: {
        id: defaultValue,
        name: query
      },
      pageSize: 25
    } as MainStemAPIControllersAdminProductsParentListAPIRequest

    mainStemApi.api
      .productsParentList(apiRequest)
      .then((apiResponse) => {
        if (apiResponse.data.wasSuccessful) {
          const productsAsSelectOptions = apiResponse.data.parentProducts?.map((product) => {
            return product && ({ label: product.name, value: product.id } as ISelectOption)
          })
          if (productsAsSelectOptions) {
            setProducts(productsAsSelectOptions)
          }
        }
      })
      .finally(() => {
        setLoading(false)
      })
  }

  useEffect(() => {
    if (defaultValue && products.length > 0) {
      const match = products.find((product) => product.value === defaultValue)
      if (match) {
        setSelectedProduct(match)
      }
    }
  }, [defaultValue, products])

  useEffect(() => {
    if (defaultOption) {
      setSelectedProduct(defaultOption)
    }
  }, [defaultOption])

  useOnFirstLoad(() => {
    loadData('')
  })

  return (
    <>
      <Label>Parent Product</Label>
      <AsyncSelect
        defaultOptions={products}
        isClearable
        isLoading={loading}
        loadOptions={(inputValue, callback) => {
          setLoading(true)

          const apiRequest = {
            filter: {
              name: inputValue
            },
            pageSize: 25
          }

          mainStemApi.api
            .productsParentList(apiRequest)
            .then((apiResponse) => {
              if (apiResponse.data.wasSuccessful) {
                const productsAsSelectOptions = apiResponse.data.parentProducts?.map((product) => {
                  return product && ({ label: product.name, value: product.id } as ISelectOption)
                })
                if (productsAsSelectOptions) {
                  setProducts(productsAsSelectOptions)
                  callback(productsAsSelectOptions)
                }
              }
            })
            .finally(() => {
              setLoading(false)
            })
        }}
        onChange={(value) => {
          setSelectedProduct(value || undefined)
          onSelected(value || undefined)
        }}
        value={selectedProduct}
      />
    </>
  )
}

export { SelectParentProduct }
