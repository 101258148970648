// React
import { useCallback, useContext, useEffect, useState } from 'react'
// Routing
import { useParams } from 'react-router-dom'
// MainStem - UI
import {
  Button,
  Card,
  CardBody,
  CardHeader,
  CardHeaderTitle,
  CardHeaderActions,
  FormattedCurrency,
  FormattedDateTime,
  InvoiceInquiryList,
  Loader,
  Stepper,
  Tabs,
  Tab,
  toast,
  useOnFirstLoad,
  Alert,
  PageTitle,
  theme
} from '@mainstem/react-mainstem'
// DEPRECATE : MainStem - UI
import { ActivityLogTable, Shop, EventBus, PrettyTable } from '@mainstem/mainstem-react-app'
// Icons
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faPlus, faSync, faUndo } from '@fortawesome/pro-duotone-svg-icons'
// MainStem - API
import {
  MainStemApi,
  MainStemAPIControllersAdminOrdersDetailsAPIResponse,
  MainStemAPIControllersAdminOrdersDetailsPaymentsAPIResponsePaymentDetails,
  MainStemAPIControllersAdminPaymentLinksPaymentLinksOnFulfillmentPaymentLinks,
  MainStemAPIControllersAdminPaymentLinksPaymentLinksOnOrderAPIResponse,
  MainStemAPIControllersAdminPaymentLinksPaymentTransactionsNotLinkedPaymentTransactionDetail,
  MainStemSharpHelpersLoggingOrderEmailedVerificationEntry as APIResponseEmailLog,
  MainStemAPIControllersAdminOrdersDetailsAPIResponseOrderLineItemDetails
} from 'api-new'
// Global - Context
import { AppContext } from 'context'
// Global - Components
import { NotesList } from 'components/NotesList'
import { ShipmentTrackingOrderList } from 'components/ShipmentTrackingOrderList'
// Local - Components
import FulfillmentMethods from './components/FulfillmentMethods'
import OrderDetailsHeader from './components/OrderDetailsHeader'
import OrderLineItemTable from './components/OrderLineItemTable'
import ModalPaymentSuccess from './components/ModalPaymentSuccess'
import ModalPaymentFailed from './components/ModalPaymentFailed'
import PaymentsTable from './components/PaymentsTable'
import ReturnsOnOrder from './components/ReturnsOnOrder'
import { columnsEmailVerificationLogs, optionsEmailVerificationLogs } from './tables/table-email-verification-logs'
import { PageRequisitionDetailsPurchaseOrders } from './components'
import AddInvoiceForm from './components/FulfillmentMethods/AddInvoiceForm'

const PageRequisitionDetails: React.FC = () => {
  // Routing
  const { paymentStatus, uuid } = useParams()
  // Context
  const { loggedInUser } = useContext<any>(AppContext)
  // Loading Indicators
  const [loading, setLoading] = useState<boolean>(true)
  const [loadingPayments, setLoadingPayments] = useState<boolean>(false)
  const [loadingEmailVerificationLogs, setLoadingEmailVerificationLogs] = useState<boolean>(false)
  // View State
  const [lineItemsToBeInvoiced, setLineItemsToBeInvoiced] = useState<
    MainStemAPIControllersAdminOrdersDetailsAPIResponseOrderLineItemDetails[]
  >([])
  const [linkedPayments, setLinkedPayments] = useState<
    MainStemAPIControllersAdminPaymentLinksPaymentLinksOnFulfillmentPaymentLinks[]
  >([])
  const [missingLinks, setMissingLinks] = useState<
    MainStemAPIControllersAdminPaymentLinksPaymentTransactionsNotLinkedPaymentTransactionDetail[]
  >([])
  const [order, setOrder] = useState<MainStemAPIControllersAdminOrdersDetailsAPIResponse | undefined>(undefined)
  const [paymentDetails, setPaymentDetails] = useState<
    MainStemAPIControllersAdminPaymentLinksPaymentLinksOnOrderAPIResponse | undefined
  >(undefined)
  const [payments, setPayments] = useState<
    MainStemAPIControllersAdminOrdersDetailsPaymentsAPIResponsePaymentDetails[] | undefined
  >([])
  const [showCatalogBrowser, setShowCatalogBrowser] = useState<boolean>(false)
  const [emailVerificationLogs, setEmailVerificationLogs] = useState<APIResponseEmailLog[]>([])
  const [activityLogs, setActivityLogs] = useState([])
  // View State - Counts
  const [countShipments, setCountShipments] = useState<number>(0)
  const [countNotes, setCountNotes] = useState<number>(0)
  const [countReturns, setCountReturns] = useState<number>(0)

  const loadPaymentLinks = useCallback((orderID, fulfillmentMethods) => {
    const apiRequest = {
      ids: [orderID]
    }
    MainStemApi.api.paymentLinksOnOrder(apiRequest).then((apiResponse) => {
      setPaymentDetails(apiResponse.data)
    })
    MainStemApi.api.paymentLinksUnlinkedPayments(apiRequest).then((apiResponse) => {
      setMissingLinks(apiResponse.data.paymentTransactions || [])
    })
    apiRequest.ids = fulfillmentMethods.map((method) => method.id)
    MainStemApi.api.paymentLinksOnFulfillment(apiRequest).then((apiResponse) => {
      setLinkedPayments(apiResponse.data.paymentLinks || [])
    })
  }, [])

  const loadPayments = useCallback(
    async (orderUUID) => {
      if (!orderUUID) {
        orderUUID = order?.uuid
      }
      setLoadingPayments(true)
      await MainStemApi.api.ordersDetailsPayments({ orderUUID }).then((apiResponse) => {
        if (apiResponse.data.wasSuccessful) {
          setPayments(apiResponse.data.payments || [])
        }
        setLoadingPayments(false)
      })
    },
    [order]
  )

  const loadEmailLogs = useCallback(
    (orderUUID) => {
      if (!orderUUID) {
        orderUUID = order?.uuid
      }
      setLoadingEmailVerificationLogs(true)
      return MainStemApi.api.ordersEmailVerificationLogs({ orderUUID }).then((apiResponse) => {
        setEmailVerificationLogs(apiResponse.data.logs || [])
        setLoadingEmailVerificationLogs(false)
        return apiResponse.data
      })
    },
    [order]
  )

  const loadOrderDetails = useCallback(() => {
    setLoading(true)
    const apiRequest = {
      uuid
    }
    MainStemApi.api.ordersDetails(apiRequest).then((apiResponse) => {
      setOrder(apiResponse.data)
      loadEmailLogs(apiResponse.data.uuid)
      loadPayments(apiResponse.data.uuid)
      loadPaymentLinks(apiResponse.data.id, apiResponse.data.fulfillmentMethods)
      setShowCatalogBrowser(
        showCatalogBrowser ||
          apiResponse.data.fulfillmentMethods?.length === 0 ||
          apiResponse.data.lineItems?.length === 0
      )
      if (apiResponse.data.lineItems && apiResponse.data.lineItems.length > 0) {
        const allFoundLineItems = apiResponse.data.lineItems.filter((lineItem) => lineItem.quantityToBeInvoiced > 0)
        setLineItemsToBeInvoiced(allFoundLineItems)
      }
      setLoading(false)
    })
  }, [uuid, showCatalogBrowser, loadPayments, loadPaymentLinks, loadEmailLogs])

  const handleProductSelected = async (product, quantity) => {
    const apiRequest = {
      productId: product.productId,
      orderId: order?.id,
      quantity: quantity,
      locationID: order?.locationID
    }
    await MainStemApi.api.orderLineItemCreate(apiRequest).then((response) => {
      if (response.data.wasSuccessful) {
        loadOrderDetails()
        toast.success('Successfully added product to the quote')
      } else {
        toast.success('Failed to add to the quote')
      }
    })
  }

  const registerEvents = useCallback(() => {
    // Register to events that can occur --
    EventBus.on(document, 'getOrderDetails', loadOrderDetails)
  }, [loadOrderDetails])

  const handleGoToProductDetails = () => {}

  /***
   * Fires when user leaves the page
   */
  useEffect(() => {
    return () => {
      console.log('removing listeners')
      EventBus.remove(document, 'order-details')
    }
  }, [])

  /***
   * Fires when user enters the page
   */
  useOnFirstLoad(() => {
    loadOrderDetails()
    registerEvents()
  })

  return (
    <>
      <PageTitle
        icon={theme.icons.mainstem.requisition}
        subtitle={
          order
            ? `Viewing details about requsition #${order.id} for the location "${order.location?.name}"`
            : 'Loading...'
        }
        title={`Requisition Details ${order ? `#${order.id}` : ' Loading...'}`}
      />
      {loading || loadingPayments || !order ? (
        <Loader />
      ) : (
        <>
          <Stepper
            steps={[
              {
                key: 1,
                label: 'Created',
                isDone: true
              },
              {
                key: 2,
                label: 'Approved',
                isDone: order?.dateApproved ? true : false
              },
              {
                key: 3,
                label: 'Shipping',
                isDone:
                  order?.fulfillmentMethods &&
                  order?.fulfillmentMethods.filter((fulfillment) => {
                    return fulfillment.dateShipped
                  }).length > 0
                    ? true
                    : false
              },
              {
                key: 4,
                label: 'Completed',
                isDone: order?.status === 'Complete'
              }
            ]}
          />
          <br />
          <Tabs tabs2>
            <Tab tabKey='details' title='Details'>
              <OrderDetailsHeader
                fulfillmentMethods={order?.fulfillmentMethods || []}
                lineItems={order?.lineItems || []}
                linkedPayments={linkedPayments}
                loading={loading}
                loggedInUser={loggedInUser}
                missingLinks={missingLinks}
                onOrderDuplicated={() => {
                  loadOrderDetails()
                  loadPayments(undefined)
                  setLoading(true)
                }}
                onOrderUpdate={loadOrderDetails}
                onPayForOrder={() => {
                  // setShowPaymentModal(true)
                }}
                order={order}
                paymentDetails={paymentDetails}
                paymentLinks={linkedPayments}
                payments={payments}
              />
            </Tab>
            <Tab length={order.lineItems?.length || 0} tabKey='items' title='Items'>
              <Card>
                {order.status !== 'Cancelled' && order.lineItems && order.lineItems.length > 0 ? (
                  <>
                    <CardHeader
                      actions={
                        <CardHeaderActions>
                          {!showCatalogBrowser && order.fulfillmentMethods && order.fulfillmentMethods.length > 0 ? (
                            <Button color='primary' onClick={() => setShowCatalogBrowser(true)}>
                              <FontAwesomeIcon icon={faPlus} />
                              &nbsp;Add Product
                            </Button>
                          ) : null}
                          {showCatalogBrowser && order.fulfillmentMethods && order.fulfillmentMethods.length > 0 ? (
                            <Button color='primary' onClick={() => setShowCatalogBrowser(false)}>
                              <FontAwesomeIcon icon={faUndo} />
                              &nbsp;Cancel Add Product
                            </Button>
                          ) : null}
                        </CardHeaderActions>
                      }
                      title={<CardHeaderTitle>Products On Requisition</CardHeaderTitle>}
                    />
                  </>
                ) : null}
                <CardBody>
                  {order.lineItems && order.lineItems.length > 0 ? (
                    <OrderLineItemTable
                      lineItems={order.lineItems}
                      onRefresh={() => {
                        loadOrderDetails()
                        toast.success('Successfully updated product on quote!')
                      }}
                    />
                  ) : null}
                </CardBody>
              </Card>
              {order.status !== 'Cancelled' && showCatalogBrowser ? (
                <>
                  <Shop
                    impersonationLocationID={order.locationID}
                    onGoToProductDetails={handleGoToProductDetails}
                    onProductSelected={handleProductSelected}
                  />
                </>
              ) : null}
            </Tab>
            <Tab length={order.purchaseOrders.length || 0} tabKey='purchase-orders' title='Purchase Orders'>
              <PageRequisitionDetailsPurchaseOrders purchaseOrders={order.purchaseOrders} />
            </Tab>
            <Tab length={order.fulfillmentMethods?.length || 0} tabKey='invoices' title='Invoices'>
              {!order.dateApproved && (
                <>
                  <Alert color='info' title='Requisition Not Approved'>
                    This requisition has not been approved yet. Please approve it before creating invoices.
                  </Alert>
                </>
              )}
              {order.fulfillmentMethods && order.fulfillmentMethods.length > 0 && (
                <FulfillmentMethods
                  fulfillmentMethods={order.fulfillmentMethods}
                  lineItemsToBeInvoiced={lineItemsToBeInvoiced}
                  orderID={order.id}
                  orderUUID={uuid}
                />
              )}
              {lineItemsToBeInvoiced.length > 0 && (
                <AddInvoiceForm lineItemsToBeInvoiced={lineItemsToBeInvoiced} orderID={order.id} />
              )}
            </Tab>
            <Tab length={order.fulfillmentMethods?.length || 0} tabKey='inquiries' title='Inquiries'>
              {order?.fulfillmentMethods &&
                order.fulfillmentMethods.map((fulfillmentMethod, index) => {
                  return (
                    <div key={index} style={{ marginBottom: 25 }}>
                      <div
                        style={{
                          boxSizing: 'border-box',
                          color: '#333',
                          fontSize: 16,
                          fontWeight: 700,
                          lineHeight: 1,
                          marginBottom: 10,
                          textAlign: 'center',
                          textTransform: 'uppercase'
                        }}
                      >
                        Inquiry #{fulfillmentMethod.id}
                      </div>

                      <InvoiceInquiryList
                        createSettings={{
                          userName: loggedInUser.username,
                          subjectOptionType: 'Admin' // Used to conditionally render select options in ModalInvoiceInquiryCreate
                        }}
                        invoiceId={fulfillmentMethod.id}
                        mode='order-details' // 'admin' | 'dashboard' | 'order-details'
                        // Required
                        onClick={(selectedId) => {
                          console.log('selectedId', selectedId)
                        }}
                      />
                    </div>
                  )
                })}
            </Tab>
            <Tab length={countShipments} tabKey='shipping' title='Shipping'>
              {!order.dateApproved && (
                <>
                  <Alert color='info' title='Requisition Not Approved'>
                    This requisition has not been approved yet. Please approve it before creating invoices.
                  </Alert>
                </>
              )}
              {order.fulfillmentMethods && order.fulfillmentMethods.length > 0 && (
                <ShipmentTrackingOrderList
                  invoiceIDs={order.fulfillmentMethods.map((i) => {
                    return i.id
                  })}
                  onActionApplied={() => {}}
                  orderID={order.id}
                  setShipmentCount={setCountShipments}
                />
              )}
            </Tab>
            <Tab length={0} tabKey='billing' title='Billing'>
              <Tabs vertical>
                <Tab length={payments?.length || 0} tabKey='billing-payment' title='Payments'>
                  {payments && payments.length > 0 && <PaymentsTable payments={payments} />}
                </Tab>
                <Tab tabKey='terms' title='Terms'>
                  {order?.termsOrderDetail?.orderBalanceTerm && (
                    <div>
                      <h3>Terms Details</h3>
                      <table className='full-width table-sm table-bordered'>
                        <tr>
                          <td>
                            <small>Payment Due</small>
                          </td>
                          <td>
                            <small>
                              <strong>
                                <FormattedCurrency value={order.termsOrderDetail.paymentDue || 0} />
                              </strong>
                            </small>
                          </td>
                        </tr>
                        <tr>
                          <td>
                            <small>Terms amount applied</small>
                          </td>
                          <td>
                            <small>
                              <strong>
                                <FormattedCurrency value={order.termsOrderDetail.orderBalanceTerm.amount || 0} />
                              </strong>
                            </small>
                          </td>
                        </tr>
                        <tr>
                          <td>
                            <small>Terms were applied on</small>
                          </td>
                          <td>
                            <small>
                              {/* {order.termsOrderDetail?.dateCreated && (
                                <FormattedDateTime datetime={order.termsOrderDetail.dateCreated} />
                              )} */}
                            </small>
                          </td>
                        </tr>
                        <tr>
                          <td>
                            <small>Payment due on</small>
                          </td>
                          <td>
                            <small>
                              {order.termsOrderDetail.paymentDateDue ? (
                                <FormattedDateTime datetime={order.termsOrderDetail.paymentDateDue} />
                              ) : (
                                <div>No invoices have shipped.</div>
                              )}
                            </small>
                          </td>
                        </tr>
                        <tr>
                          <td>
                            <small>Based on invoice shipped on</small>
                          </td>
                          <td>
                            <small>
                              {order.termsOrderDetail.basedOnShippedDate ? (
                                <FormattedDateTime datetime={order.termsOrderDetail.basedOnShippedDate} />
                              ) : (
                                <div>No invoices have shipped.</div>
                              )}
                            </small>
                          </td>
                        </tr>
                      </table>
                      <hr />
                      {/* {order.termsOrderDetail?..invoiceGridItemlectables?.length > 0 ? (
                        <>
                          {order.termsOrderDetail.invoiceGridItemlectables.map((invoice) => {
                            return (
                              <Fragment key={invoice.id}>
                                <Grid>
                                  <GridItem sm={3}>
                                    Amount Due: &nbsp;
                                    <FormattedCurrency value={invoice.amount} />
                                  </GridItem>
                                  <GridItem sm={3}>
                                    <>
                                      {invoice.dateGridItemlectable ? (
                                        <>
                                          Date GridItemlectable : &nbsp;
                                          <FormattedDateTime datetime={invoice.dateGridItemlectable} />
                                          <br />
                                          Based On Date Shipped : &nbsp;
                                          <FormattedDateTime datetime={invoice.shippedOn} />
                                        </>
                                      ) : (
                                        <>Invoice Has Not Shipped</>
                                      )}
                                    </>
                                  </GridItem>
                                </Grid>
                                <hr />
                              </Fragment>
                            )
                          })}
                        </>
                      ) : (
                        <>No Invoices Have Shipped.</>
                      )} */}
                    </div>
                  )}
                </Tab>
              </Tabs>
            </Tab>
            <Tab length={countNotes} tabKey='notes' title='Notes'>
              <NotesList
                allowPrivate
                documentID={order.id}
                documentType='order'
                setNoteCount={setCountNotes}
                title='Order Notes'
              />
            </Tab>
            <Tab length={0} tabKey='logs' title='Logs'>
              <Tabs vertical>
                <Tab length={activityLogs.length ? activityLogs.length : 0} tabKey='logs-activity' title='Activity Log'>
                  {order && order?.id && (
                    <ActivityLogTable
                      documentID={order.id}
                      documentType='Order'
                      setActivityLogs={setActivityLogs}
                      title='Order Activity Logs'
                    />
                  )}
                </Tab>
                <Tab length={emailVerificationLogs?.length || 0} tabKey='logs-email' title='Email Logs'>
                  <PrettyTable
                    columns={columnsEmailVerificationLogs}
                    customButtons={
                      <>
                        <Button
                          icon={faSync}
                          onClick={() => {
                            loadEmailLogs(order.uuid)
                          }}
                        >
                          Refresh
                        </Button>
                      </>
                    }
                    data={emailVerificationLogs}
                    keyField='rowKey'
                    loading={loadingEmailVerificationLogs}
                    options={optionsEmailVerificationLogs}
                    title='Email Verification Logs'
                  />
                </Tab>
              </Tabs>
            </Tab>
            <Tab length={countReturns} tabKey='returns' title='Returns'>
              <ReturnsOnOrder setReturnsCount={setCountReturns} />
            </Tab>
          </Tabs>
        </>
      )}
      {paymentStatus === 'success' ? (
        <ModalPaymentSuccess />
      ) : paymentStatus === 'error' ? (
        <ModalPaymentFailed
          onPayForOrder={() => {
            // setShowPaymentModal(true)
          }}
        />
      ) : null}
      {/* Needs some extra padding */}
      <br />
      <br />
      {/* <pre>{JSON.stringify(order, null, 1)}</pre> */}
    </>
  )
}

export default PageRequisitionDetails
