// Uncharged Freight Shipments
import { HTTP } from 'config'

/**
 * Request Body:
  int? invoiceID // One or the other.
  string invoiceUUID
 */
export async function apiShipmentTrackingOfInvoice(apiRequest) {
  let apiResponse = {
    wasSuccessful: false
  }

  await HTTP.post('shipment-tracking/invoice', apiRequest)
    .then((res) => {
      if (res.data) apiResponse = res.data
    })
    .catch(() => {})

  return apiResponse
}
