// React
import React, { Fragment, useState, useEffect, useCallback } from 'react'
// DEPRECATE : ReactStrap
import {
  Col,
  Row,
  Input,
  FormGroup,
  Label,
  CardHeader,
  Card,
  UncontrolledCollapse,
  Button,
  CardBody,
  ButtonGroup,
  Container
} from 'reactstrap'
// DEPRECATE : MainStem UI
import {
  PrettyTable,
  PrettyDateTime,
  PrettyNumber,
  PermissiveButton,
  PrettyTabs,
  PrettyTab,
  PrettySwitch
} from '@mainstem/mainstem-react-app'
// DEPRECATE : React Toastify
import { toast } from 'react-toastify'
// Icons
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faFilter, faEdit, faBan } from '@fortawesome/pro-duotone-svg-icons'
// DEPRECATE : MainStem - API
import { apiFulfillmentTrackerToCsv } from 'api/fulfillment-tracker/fulfillment-tracker-csv-export'
import { apiTrackFulfillmentsList } from 'api/fulfillment-tracker/fulfillment-tracker-list'
import { apiUpdateTrackedFulfillment } from 'api/fulfillment-tracker/fulfillment-tracker-update'
import { apiTrackFulfillmentDetails } from 'api/fulfillment-tracker/fulfillment-tracker-details'
// Global Components
import { FilterDebouncedInput } from 'components/FilterDebouncedInput'
import { FilterToggleSwitch } from 'components/FilterToggleSwitch'
import { ShipmentTrackingInvoiceList } from 'components/ShipmentTrackingInvoiceList'
import { NotesList } from 'components/NotesList'
import InvoiceProblems from 'components/InvoiceProblems'
// Local Components
import { invoiceTrackerColumns, invoiceTrackerPageOptions, invoiceTrackerRemoteOptions } from './columns'

const FulfillmentTrackerInvoicesAll: React.FC = () => {
  const [totalIssues, setTotalIssues] = useState(0)
  const [doneLoading, setDoneLoading] = useState(false)
  const [trackedInvoices, setTrackedInvoices] = useState([])
  const [totalFulfilled, setTotalFulfilled] = useState(0)
  const [totalFulfillments, setTotalFulfillments] = useState(0)
  const [totalNoProblems, setTotalNoProblems] = useState(0)
  const [totalProblems, setTotalProblems] = useState(0)
  const [totalUnfulfilled, setTotalUnFilfilled] = useState(0)
  const [totalUniqueOrders, setTotalUniqueOrders] = useState(0)
  const [resultsTotal, setResultsTotal] = useState(0)
  // Pagination --
  const [currentFilters, setFilters] = useState()
  const [currentPage, setPage] = useState(1)
  const [currentPageSize, setPageSize] = useState(50)
  // Filters.
  // Delayed Inputs
  const [customerID, setCustomerID] = useState('')
  const [customerName, setCustomerName] = useState('')
  const [supplierID, setSupplierID] = useState('')
  const [supplierName, setSupplierName] = useState('')
  const [orderID, setOrderID] = useState('')
  const [fulfillmentID, setFulfillmentID] = useState('')
  // Toggle Switch Filters -- Null means the toggle isn't set initally.
  // true/false will autoset the filter to that value.
  const [hasShippingCost, setHasShippingCost] = useState(null)
  const [hasOrganization, setHasOrganization] = useState(null)
  const [hasProblem, setHasProblem] = useState(null)
  const [supplierNotified, setSupplierNotified] = useState(null)
  const [invoiceShipped, setInvoiceShipped] = useState(null)
  const [paymentStatus, setPaymentStatus] = useState(null)
  const [hasTracking, setHasTracking] = useState(null)
  const [isAcknowledged, setIsAcknowledged] = useState(null)
  const [isPrivateSupplier, setIsPrivateSupplier] = useState(false)
  const [isRFQOrder, setIsRFQOrder] = useState(null)

  const [isPending, setIsPending] = useState(null)
  const [isRejected, setIsRejected] = useState(null)

  // Stale Data Reload
  const staleTimer = 15 * 60 * 1000 // 15 minutes before it loads new data, if no new data has been pulled before hand.
  const [currentStaleTime, setCurrentStaleTime] = useState(0)
  const [timeLoadedAt, setTimeLoadedAt] = useState(new Date()) // Loading the data now.
  const [expandedRows, setExpandedRows] = useState([])

  const [exportCSVLoading, setExportCSVLoading] = useState(false)

  const toCsvExport = () => {
    const pageRequest = {
      filters: currentFilters,
      sortField: 'dateApproved',
      sortOrder: 'desc'
    }
    setExportCSVLoading(true)
    apiFulfillmentTrackerToCsv(pageRequest).then((apiResponse) => {
      if (apiResponse.wasSuccessful) {
        toast.success(apiResponse.message)
      } else {
        toast.error('Error exporting file')
      }
      setExportCSVLoading(false)
    })
  }

  const handleTableChange = (type, page, pageSize, filters, sortField, sortOrder) => {
    if (pageSize !== currentPageSize) {
      page = 1
      setPage(1)
    }
    loadPageOfData({
      page: page,
      pageSize: pageSize,
      filters: currentFilters,
      sortField: sortField || 'dateApproved',
      sortOrder: sortOrder || 'desc'
    })
  }

  const loadPageOfData = useCallback((pageRequest) => {
    // Loading a new page of results.
    if (!pageRequest.page) {
      pageRequest.page = 1
    }
    setPage(pageRequest.page)
    setPageSize(pageRequest.pageSize)
    setTrackedInvoices([])
    apiTrackFulfillmentsList(pageRequest).then((apiResponse) => {
      setTrackedInvoices(apiResponse.fulfillments)
      setResultsTotal(apiResponse.resultsTotalCount)

      setExpandedRows([])
      setTotalFulfilled(apiResponse.totalFulfilled)
      setTotalFulfillments(apiResponse.totalFulfillments)
      setTotalNoProblems(apiResponse.totalNoProblems)
      setTotalProblems(apiResponse.totalProblems)
      setTotalUnFilfilled(apiResponse.totalUnfulfilled)
      setTotalUniqueOrders(apiResponse.totalUniqueOrders)
      setDoneLoading(true)
      // Reset Stale data loader.
      setCurrentStaleTime(0)
      setTimeLoadedAt(new Date()) // Right now
    })
  }, [])

  useEffect(() => {
    const newFilters = {
      customerID,
      customerName,
      supplierID,
      supplierName,

      orderID,
      fulfillmentID,
      hasOrganization: hasOrganization,
      hasProblem: hasProblem,

      notified: supplierNotified,
      isFulfilled: invoiceShipped,
      paymentStatusComplete: paymentStatus,
      hasTrackingDetails: hasTracking,

      isAcknowledged: isAcknowledged,
      hasShippingCost: hasShippingCost,
      isPrivateSupplier: isPrivateSupplier,
      isRFQOrder: isRFQOrder,

      isPending: isPending,
      isRejected: isRejected
    }

    setFilters(newFilters)

    loadPageOfData({
      pageSize: currentPageSize,
      filters: newFilters
    })
  }, [
    currentPageSize,

    customerID,
    customerName,
    supplierID,
    supplierName,

    orderID,
    fulfillmentID,
    hasOrganization,
    hasProblem,

    supplierNotified,
    invoiceShipped,
    paymentStatus,
    hasTracking,

    isAcknowledged,
    hasShippingCost,
    isPrivateSupplier,
    isRFQOrder,

    isPending,
    isRejected,

    loadPageOfData
  ])

  useEffect(() => {
    const interval = setInterval(() => {
      if (!currentStaleTime) return
      // Just counting until we hit the stale time, and then we call data reload.
      setCurrentStaleTime(currentStaleTime + 1000)
      if (currentStaleTime >= staleTimer) {
        loadPageOfData({
          page: currentPage,
          pageSize: currentPageSize,
          filters: currentFilters
        })
        setCurrentStaleTime(0)
        setTimeLoadedAt(new Date()) // Right now
      }
    }, 1000)
    return () => clearInterval(interval)
  }, [staleTimer, currentStaleTime, setCurrentStaleTime, loadPageOfData, currentPage, currentPageSize, currentFilters])

  /* ======================
      fulfillmentCounts
  ====================== */

  const fulfillmentCounts = (
    <Card>
      <br />
      <Row className='align-middle text-center border-200'>
        <Col>
          <h6># Total Invoices</h6>
        </Col>
        <Col>
          <h6># Unique Orders</h6>
        </Col>
        <Col>
          <h6># Fulfilled</h6>
        </Col>
        <Col>
          <h6># Unfilfilled</h6>
        </Col>
        <Col>
          <h6># Problems</h6>
        </Col>
        <Col>
          <h6># No Problems</h6>
        </Col>
      </Row>
      <Row className='align-middle text-center border-200'>
        <Col>
          <h5>
            <PrettyNumber value={totalFulfillments} />
          </h5>
        </Col>
        <Col>
          <h5>
            <PrettyNumber value={totalUniqueOrders} />
          </h5>
        </Col>
        <Col>
          <h5>
            <PrettyNumber value={totalFulfilled} />
          </h5>
        </Col>
        <Col>
          <h5>
            <PrettyNumber value={totalUnfulfilled} />
          </h5>
        </Col>
        <Col>
          <h5>
            <PrettyNumber value={totalProblems} />
          </h5>
        </Col>
        <Col>
          <h5>
            <PrettyNumber value={totalNoProblems} />
          </h5>
        </Col>
      </Row>
    </Card>
  )

  /* ======================
      tableSearchFilters
  ====================== */

  const tableSearchFilters = (
    <Fragment>
      <div>
        <span style={{ marginRight: 10 }}>
          <Button id='toggler'>
            <FontAwesomeIcon icon={faFilter} />
            &nbsp;Show Filters
          </Button>
        </span>

        <span>
          Last Loaded <PrettyDateTime datetime={timeLoadedAt} />
        </span>
      </div>

      <UncontrolledCollapse toggler='#toggler'>
        <br />

        <div
          style={{
            backgroundColor: '#fff',
            border: '1px solid #ccc',
            borderRadius: 10
          }}
        >
          <Row
            style={{
              padding: '20px 20px 4px 20px'
            }}
          >
            <Col md={3}>
              <FormGroup>
                <Label>Customer ID</Label>
                <FilterDebouncedInput filterType='int' setValue={setCustomerID} type='text' value={customerID} />
              </FormGroup>
            </Col>

            <Col md={3}>
              <FormGroup>
                <Label>Customer Name</Label>
                <FilterDebouncedInput setValue={setCustomerName} type='text' value={customerName} />
              </FormGroup>
            </Col>

            <Col md={3}>
              <FormGroup>
                <Label>Supplier ID</Label>
                <FilterDebouncedInput filterType='int' setValue={setSupplierID} type='text' value={supplierID} />
              </FormGroup>
            </Col>

            <Col md={3}>
              <FormGroup>
                <Label>Supplier Name</Label>
                <FilterDebouncedInput setValue={setSupplierName} type='text' value={supplierName} />
              </FormGroup>
            </Col>

            <Col md={3}>
              <FormGroup>
                <Label>Order ID</Label>
                <FilterDebouncedInput filterType='int' setValue={setOrderID} type='text' value={orderID} />
              </FormGroup>
            </Col>

            <Col md={3}>
              <FormGroup>
                <Label>Fulfillment ID</Label>
                <FilterDebouncedInput filterType='int' setValue={setFulfillmentID} type='text' value={fulfillmentID} />
              </FormGroup>
            </Col>

            <Col md={3}>
              <FormGroup>
                <Label>Has Organization</Label>
                <br />
                <FilterToggleSwitch
                  offText='No Organization'
                  onText='Has Organization'
                  setValue={setHasOrganization}
                  value={hasOrganization}
                />
              </FormGroup>
            </Col>

            <Col md={3}>
              <FormGroup>
                <Label>Has Problems</Label>
                <br />
                <FilterToggleSwitch
                  offText='No Problems'
                  onText='Has Problems'
                  setValue={setHasProblem}
                  value={hasProblem}
                />
              </FormGroup>
            </Col>

            <Col md={3}>
              <FormGroup>
                <Label>Supplier Notified</Label>
                <br />
                <FilterToggleSwitch
                  offText='Not Notified'
                  onText='Notified'
                  setValue={setSupplierNotified}
                  value={supplierNotified}
                />
              </FormGroup>
            </Col>

            <Col md={3}>
              <FormGroup>
                <Label>Invoices Shipped</Label>
                <br />
                <FilterToggleSwitch
                  offText='Not Shipped'
                  onText='Shipped'
                  setValue={setInvoiceShipped}
                  value={invoiceShipped}
                />
              </FormGroup>
            </Col>

            <Col md={3}>
              <FormGroup>
                <Label>Payment Status</Label>
                <br />
                <FilterToggleSwitch
                  offText='Open'
                  onText='Complete'
                  setValue={setPaymentStatus}
                  value={paymentStatus}
                />
              </FormGroup>
            </Col>

            <Col md={3}>
              <FormGroup>
                <Label>Has Tracking</Label>
                <br />
                <FilterToggleSwitch
                  offText='No Tracking'
                  onText='Has Tracking'
                  setValue={setHasTracking}
                  value={hasTracking}
                />
              </FormGroup>
            </Col>

            <Col md={3}>
              <FormGroup>
                <Label>Invoices Acknowledged</Label>
                <br />
                <FilterToggleSwitch
                  offText='Not Acknowledged'
                  onText='Acknowledged'
                  setValue={setIsAcknowledged}
                  value={isAcknowledged}
                />
              </FormGroup>
            </Col>

            <Col md={3}>
              <FormGroup>
                <Label>Has Shipping Set</Label>
                <br />
                <FilterToggleSwitch
                  offText='Missing Shipping Cost'
                  onText='Shipping Cost Set'
                  setValue={setHasShippingCost}
                  value={hasShippingCost}
                />
              </FormGroup>
            </Col>

            <Col md={3}>
              <FormGroup>
                <Label>Verified|Private Supplier</Label>
                <br />
                <FilterToggleSwitch
                  offText='Verified Supplier'
                  onText='Private Supplier'
                  setValue={setIsPrivateSupplier}
                  value={isPrivateSupplier}
                />
              </FormGroup>
            </Col>

            <Col md={3}>
              <FormGroup>
                <Label>Custom/RFQ Order</Label>
                <br />
                <FilterToggleSwitch
                  offText='Regular Order'
                  onText='RFQ | Custom Order'
                  setValue={setIsRFQOrder}
                  value={isRFQOrder}
                />
              </FormGroup>
            </Col>

            <Col md={3}>
              <FormGroup>
                <Label>Pending Orders</Label>
                <br />
                <FilterToggleSwitch offText='Not Pending' onText='Pending' setValue={setIsPending} value={isPending} />
              </FormGroup>
            </Col>

            <Col md={3}>
              <FormGroup>
                <Label>Rejected Orders</Label>
                <br />
                <FilterToggleSwitch
                  offText='Not Rejected'
                  onText='Rejected'
                  setValue={setIsRejected}
                  value={isRejected}
                />
              </FormGroup>
            </Col>
          </Row>
        </div>
      </UncontrolledCollapse>
    </Fragment>
  )

  /* ======================
      loadSingleRow()
  ====================== */
  // Called in invoiceUpdate(), etc.

  const loadSingleRow = (row, rowIndex) => {
    setDoneLoading(false)
    const apiRequest = {
      fulfillmentID: row.fulfillmentId
    }
    return apiTrackFulfillmentDetails(apiRequest).then((response) => {
      const copyOfInvoices = [...trackedInvoices]
      if (response.wasSuccessful) {
        copyOfInvoices[rowIndex] = response.fulfillment
      }
      copyOfInvoices[rowIndex].loading = false
      setTrackedInvoices(copyOfInvoices)
      setDoneLoading(true)
      console.log('Done loading single..')
      return apiRequest
    })
  }

  /* ======================
      invoiceUpdate()
  ====================== */

  const invoiceUpdate = (invoice, rowIndex) => {
    const apiRequest = {
      fulfillmentId: invoice.fulfillmentId,
      fulfillmentOrderNumber: invoice.fulfillmentOrderNumber,
      hasProblem: invoice.hasProblem
    }
    setDoneLoading(false)
    apiUpdateTrackedFulfillment(apiRequest).then((apiResponse) => {
      if (apiResponse.wasSuccessful) {
        toast.success('Quick update invoice successful.')
      } else {
        toast.error('Quick update invoice failed.')
      }
      loadSingleRow(invoice, rowIndex)
    })
  }

  /* ======================
      expandRowOptions
  ====================== */

  const expandRowOptions = {
    renderer: (row, rowIndex) => {
      return (
        <React.Fragment>
          <PrettyTabs>
            <PrettyTab length={totalIssues} title='Invoice Problems'>
              <InvoiceProblems
                invoiceID={row.fulfillmentId}
                onReload={() => {
                  loadSingleRow(row, rowIndex)
                }}
                programType={row.programType}
                setTotalIssues={setTotalIssues}
              />
            </PrettyTab>
            <PrettyTab title='Invoice Notes'>
              <NotesList
                allowPrivate
                documentID={row.fulfillmentId}
                documentType='fulfillment'
                onActionApplied={() => {
                  console.log('Action applied!', row, rowIndex)
                  // Since we use Invoice notes for fulfillment Note now.
                  loadSingleRow(row, rowIndex)
                }}
                title='Invoice Notes'
              />
            </PrettyTab>
            <PrettyTab title='Update Invoice'>
              <Card>
                <CardHeader>
                  <Row>
                    <Col>
                      <h5>Update Invoice - {row.invoiceId}</h5>
                    </Col>
                    <Col className='align-self-center text-right'>
                      <PermissiveButton
                        allow
                        className='btn btn-sm btn-primary'
                        loading={row.loading}
                        onClick={() => {
                          const copyOfInvoices = [...trackedInvoices]
                          copyOfInvoices[rowIndex].loading = true
                          setTrackedInvoices(copyOfInvoices)
                          invoiceUpdate(row, rowIndex)
                        }}
                      >
                        Update
                      </PermissiveButton>
                    </Col>
                  </Row>
                </CardHeader>
                <CardBody>
                  <Container fluid>
                    <Row>
                      <Col md={{ size: 6 }}>
                        <FormGroup>
                          <Label>Invoice Has Problems:</Label>
                          <br />
                          <PrettySwitch
                            isChecked={row.hasProblem}
                            offText='No Problems'
                            onChange={({ target }) => {
                              // row.fulfillmentOrderNumber = target.value
                              const copyOfInvoices = [...trackedInvoices]
                              copyOfInvoices[rowIndex].hasProblem = target.checked
                              setTrackedInvoices(copyOfInvoices)
                            }}
                            onText='Has Problems'
                          />
                        </FormGroup>
                      </Col>
                      <Col md={{ size: 6 }}>
                        <FormGroup>
                          <Label>Supplier Order ID</Label>
                          <Input
                            onChange={({ target }) => {
                              // row.fulfillmentOrderNumber = target.value
                              const copyOfInvoices = [...trackedInvoices]
                              copyOfInvoices[rowIndex].fulfillmentOrderNumber = target.value
                              setTrackedInvoices(copyOfInvoices)
                            }}
                            type='text'
                            value={row.fulfillmentOrderNumber}
                          />
                        </FormGroup>
                      </Col>
                    </Row>
                  </Container>
                </CardBody>
              </Card>
            </PrettyTab>
            <PrettyTab title='Shipment Tracking'>
              <ShipmentTrackingInvoiceList
                invoiceID={row.fulfillmentId}
                onActionApplied={() => {
                  loadSingleRow(row, rowIndex)
                }}
              />
            </PrettyTab>
            <PrettyTab title='Order Notes'>
              <NotesList allowPrivate documentID={row.orderId} documentType='order' title='Orders Notes' />
            </PrettyTab>
          </PrettyTabs>
          <hr />
          <br />
        </React.Fragment>
      )
    },
    showExpandColumn: true,
    onlyOneExpanding: true,
    expandByColumnOnly: true,
    expanded: expandedRows,
    onExpand: (row, isExpand, rowIndex, e) => {
      if (isExpand) {
        setExpandedRows([row.fulfillmentId])
      } else {
        setExpandedRows(expandedRows.filter((x) => x !== row.fulfillmentId))
      }
    },
    // Not sure how to have the show expand + not show the top level expander.. so this overrides it.
    expandHeaderColumnRenderer: ({ isAnyExpands }) => {
      return <React.Fragment />
    },
    expandColumnRenderer: ({ expanded }) => {
      if (expanded) {
        return (
          <ButtonGroup vertical>
            <PermissiveButton allow className='px-4 px-sm-5 mb-3' color='danger' size='sm'>
              <FontAwesomeIcon icon={faBan} />
              &nbsp;Cancel Edit
            </PermissiveButton>
          </ButtonGroup>
        )
      }
      return (
        <ButtonGroup vertical>
          <PermissiveButton allow className='px-4 px-sm-5 mb-3' color='primary' size='sm'>
            <FontAwesomeIcon icon={faEdit} />
            &nbsp;Edit Details
          </PermissiveButton>
        </ButtonGroup>
      )
    }
  }

  /* ======================
          return 
  ====================== */

  return (
    <Fragment>
      <div>
        {fulfillmentCounts}

        <br />

        {tableSearchFilters}

        <br />

        <PrettyTable
          columns={invoiceTrackerColumns()}
          csvExportLoading={exportCSVLoading}
          data={trackedInvoices}
          expandRow={expandRowOptions}
          keyField='fulfillmentId'
          loading={!doneLoading}
          options={invoiceTrackerPageOptions(currentPage, currentPageSize, resultsTotal, loadPageOfData)}
          overrideCsvExport={toCsvExport}
          remoteOptions={invoiceTrackerRemoteOptions(handleTableChange)}
          title='Invoice Results'
        />
      </div>
      {/* All Pages with pagination need a bit of padding at the end. */}
      <div style={{ height: '100px' }}> </div>
    </Fragment>
  )
}

export { FulfillmentTrackerInvoicesAll }
