// React
import { useCallback, useState } from 'react'
// Routing
import { useHistory, useParams } from 'react-router-dom'
// MainStem - UI
import {
  Button,
  Card,
  CardBody,
  CardHeader,
  CardHeaderActions,
  CardHeaderTitle,
  FormattedNumber,
  Grid,
  GridItem,
  InputCurrency,
  InputDateTime,
  InputNumber,
  InputText,
  ListItemStat,
  Loader,
  MainStemSelectProductUnitOfMeasureLabel,
  MoreInfo,
  ProfileTitle,
  ProfileWrapper,
  Switch,
  Tabs,
  Tab,
  TextArea,
  theme,
  toast,
  useOnFirstLoad
} from '@mainstem/react-mainstem'
// MainStem - UI - Types
import { ISelectOption } from '@mainstem/react-mainstem/dist/components/Form/Select/types'
// Icons
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faCamera } from '@fortawesome/pro-duotone-svg-icons'
// MainStem - API
import {
  Api,
  MainStemAPIControllersAdminProductsChildDetailsAPIResponseProductDetails,
  MainStemAPIControllersAdminProductsChildUpdateAPIRequest
} from 'api-new'
// Global - Components
import { NotDone, PriceAgreements, SelectParentProduct, SelectProductApprovalStatus, SelectSupplier } from 'components'
// Local - Components
import ProductDetailsAttributes from '../components/Attributes'
import ProductDetailsProductImage from '../components/ProductImage'
import ProductDetailsTags from '../components/Tags'
import { ProductDetailsVolumePricing } from '../components/VolumePricing'
import { ISelectParentProductDefaultOption } from 'components/Select/ParentProduct/types'

const PageProductChildUpdate: React.FC = () => {
  // API
  const mainStemApi = new Api()
  // Routing
  const history = useHistory()
  const { id } = useParams()
  // Loading Indicators
  const [loading, setLoading] = useState(true)
  const [loadingSave, setLoadingSave] = useState(false)
  // Modals
  const [modalProductImageOpen, setModalProductImageOpen] = useState(false)
  // Data
  const [productDetails, setProductDetails] = useState<
    undefined | MainStemAPIControllersAdminProductsChildDetailsAPIResponseProductDetails
  >(undefined)
  // Selects
  const [selectedProductUnitOfMeasureLabel, setSelectedProductUnitOfMeasureLabel] = useState<ISelectOption | undefined>(
    undefined
  )
  const [selectedSupplierID, setSelectedSupplierID] = useState<undefined | number>(undefined)
  const [selectedParentProductID, setSelectedParentProductID] = useState<undefined | number>(undefined)
  const [newPurchasePriceRetail, setNewPurchasePriceRetail] = useState<string>('')
  const [newPurchasePriceWholesale, setNewPurchasePriceWholesale] = useState<string>('')
  const [newPurchasePriceEnterprise, setNewPurchasePriceEnterprise] = useState<string>('')
  // Related Data
  const [attributeValues, setAttributeValues] = useState<any>()
  const [volumePricing, setVolumePricing] = useState<any>()

  const loadProductDetails = useCallback(() => {
    const apiRequest = {
      id
    }
    mainStemApi.api
      .productsChildDetails(apiRequest)
      .then((apiResponse) => {
        if (apiResponse.data) {
          setProductDetails(apiResponse.data.product)
          setSelectedParentProductID(apiResponse.data.product?.parentProduct?.id)
          setSelectedSupplierID(apiResponse.data.product?.supplier?.id)
          setAttributeValues(apiResponse.data?.product?.attributeValues || [])
          setVolumePricing(apiResponse.data.product?.volumePricing || [])

          setNewPurchasePriceRetail(apiResponse.data.product?.pricePurchaseRetail?.toString() || '0')
          setNewPurchasePriceWholesale(apiResponse.data.product?.pricePurchaseWholesale?.toString() || '0')
          setNewPurchasePriceEnterprise(apiResponse.data.product?.pricePurchaseEnterprise?.toString() || '0')
        }
      })
      .finally(() => {
        setLoading(false)
      })
  }, [id])

  const handleProductUpdate = () => {
    if (productDetails) {
      setLoadingSave(true)
      const apiRequest = {
        approvalStatus: productDetails.approvalStatus,
        customsDescription: productDetails.customsDescription,
        customsHarmonizedTarrifCodes: productDetails.customsHarmonizedTarrifCodes,
        daysToFulfill: productDetails.daysToFulfill,
        defaultProfitMarginEnterprise: productDetails.defaultProfitMarginEnterprise,
        defaultProfitMarginPriceAgreements: productDetails.defaultProfitMarginPriceAgreements,
        defaultProfitMarginRetail: productDetails.defaultProfitMarginRetail,
        defaultProfitMarginVolumePricing: productDetails.defaultProfitMarginVolumePricing,
        defaultProfitMarginWholesale: productDetails.defaultProfitMarginWholesale,
        description: productDetails.description,
        flatShippingCost: productDetails.flatShippingCost,
        freeShipping: productDetails.freeShipping,
        freightShippingRequired: productDetails.freightShippingRequired,
        hasVolumePricing: productDetails.hasVolumePricing,
        hazmatFee: productDetails.hazmatFee,
        height: productDetails.height,
        id: productDetails.id,
        isApproved: productDetails.isApproved,
        isClean: productDetails.isClean,
        isCustomizable: productDetails.isCustomizable,
        isCustomProduct: productDetails.isCustomProduct,
        isDeleted: productDetails.isDeleted,
        isDroppedShipped: productDetails.isDroppedShipped,
        isNonCancellable: productDetails.isNonCancellable,
        isNonReturnable: productDetails.isNonReturnable,
        isOutOfStock: productDetails.isOutOfStock,
        isShippable: productDetails.isShippable,
        length: productDetails.length,
        manufacturerID: productDetails.manufacturerID,
        maximumPurchasableQuantity: productDetails.maximumPurchasableQuantity,
        minimumPurchasableQuantity: productDetails.minimumPurchasableQuantity,
        msrp: productDetails.msrp,
        name: productDetails.name,
        parentProductId: selectedParentProductID,
        pricePurchaseEnterprise: parseFloat(newPurchasePriceEnterprise),
        pricePurchaseRetail: parseFloat(newPurchasePriceRetail),
        pricePurchaseWholesale: parseFloat(newPurchasePriceWholesale),
        quickBooksId: productDetails.quickBooksId,
        showInStore: productDetails.showInStore,
        skuInternal: productDetails.skuInternal,
        skuManufacturer: productDetails.skuManufacturer,
        soldInQuantitiesOf: productDetails.soldInQuantitiesOf,
        supplierId: selectedSupplierID,
        supplierPartNumber: productDetails.supplierPartNumber,
        taxCode: productDetails.taxCode,
        taxExempt: productDetails.taxExempt,
        unitOfMeasureLabel: productDetails.unitOfMeasureLabel,
        unitOfMeasureValue: productDetails.unitOfMeasureValue,
        weight: productDetails.weight,
        width: productDetails.width
      } as MainStemAPIControllersAdminProductsChildUpdateAPIRequest

      mainStemApi.api
        .productsChildUpdate(apiRequest)
        .then((apiResponse) => {
          if (apiResponse.data.wasSuccessful) {
            toast.success('Successfully updated product details.')
            loadProductDetails()
          } else {
            toast.error('Failed to update product details.')
          }
        })
        .finally(() => {
          setLoadingSave(false)
        })
    }
  }

  useOnFirstLoad(() => {
    setLoading(true)
    loadProductDetails()
  })

  return loading || !productDetails ? (
    <Loader />
  ) : (
    <>
      <ProfileTitle
        actions={
          <>
            <Button
              color='fancy'
              icon={theme.icons.mainstem.products}
              onClick={() => {
                history.push(`/products/child/details/${id}`)
              }}
              style={{ marginRight: 15 }}
            >
              View Product Profile
            </Button>
            <Button color='success' icon={theme.icons.save} loading={loadingSave} onClick={handleProductUpdate}>
              Save Details
            </Button>
          </>
        }
        icons={theme.icons.mainstem.products}
        loading={loading}
        logo={productDetails.image || theme.images.notAvailable}
        name={productDetails.name || 'Loading'}
        type='Edit Product'
      />
      <ProfileWrapper>
        <Card>
          <CardBody>
            <Tabs hideCards={true} vertical={true}>
              <Tab tabKey='details' title='Details'>
                <>
                  <Grid>
                    <GridItem colSpan={8}>
                      <>
                        <SelectParentProduct
                          defaultOption={
                            productDetails.parentProduct && productDetails.parentProduct.id
                              ? ({
                                  label: productDetails.parentProduct.name,
                                  value: productDetails.parentProduct.id
                                } as ISelectParentProductDefaultOption)
                              : undefined
                          }
                          onSelected={(selectedOption) => {
                            setSelectedParentProductID(selectedOption?.value)
                          }}
                        />
                        <br />
                        <Grid cols={2}>
                          <GridItem colSpan={1}>
                            <SelectSupplier
                              defaultValue={productDetails.supplier?.id}
                              onSelected={(selectedOption) => {
                                setSelectedSupplierID(selectedOption?.value)
                              }}
                            />
                          </GridItem>
                          <GridItem colSpan={1}>
                            <InputText
                              formText='This is a part number that is provided by the supplier to identify the product.'
                              label='Supplier Part #'
                              labelRequired={true}
                              onChange={(newValue) => {
                                setProductDetails({
                                  ...productDetails,
                                  supplierPartNumber: newValue
                                })
                              }}
                              value={productDetails.supplierPartNumber || ''}
                            />
                          </GridItem>
                        </Grid>
                        <InputText
                          label='Product Name'
                          labelRequired={true}
                          onChange={(newValue) => {
                            setProductDetails({
                              ...productDetails,
                              name: newValue
                            })
                          }}
                          value={productDetails.name || ''}
                        />
                        <TextArea
                          label='Product Description'
                          onChange={(newValue) => {
                            setProductDetails({
                              ...productDetails,
                              description: newValue
                            })
                          }}
                          value={productDetails.description || ''}
                        />
                        <Grid>
                          <GridItem colSpan={6}>
                            <InputText
                              formText='This is a SKU that is managed internally at MainStem to identify the product.'
                              label='MainStem SKU'
                              labelRequired={true}
                              onChange={(newValue) => {
                                setProductDetails({
                                  ...productDetails,
                                  skuInternal: newValue
                                })
                              }}
                              value={productDetails.skuInternal || ''}
                            />
                          </GridItem>
                          <GridItem colSpan={6}>
                            <InputText
                              formText='This is a SKU that is provided by the manufacturer to identify the product.'
                              label='Manufacturer SKU'
                              labelRequired={true}
                              onChange={(newValue) => {
                                setProductDetails({
                                  ...productDetails,
                                  skuManufacturer: newValue
                                })
                              }}
                              value={productDetails.skuManufacturer || ''}
                            />
                          </GridItem>
                        </Grid>
                      </>
                    </GridItem>
                    <GridItem colSpan={4}>
                      <>
                        <Switch
                          formText='Whether or not the product is considered "clean" by a MainStem employee.'
                          isChecked={productDetails.isClean || false}
                          offText='Not Clean'
                          onChange={() => {
                            setProductDetails((previous) => {
                              return {
                                ...productDetails,
                                isClean: !previous?.isClean
                              }
                            })
                          }}
                          onText='Clean'
                        />
                        <br />
                        <Switch
                          formText='Whether or not the product is considered "archived".'
                          isChecked={!productDetails.isDeleted}
                          offText='Archived'
                          onChange={() => {
                            setProductDetails((previous) => {
                              return {
                                ...productDetails,
                                isDeleted: !previous?.isDeleted
                              }
                            })
                          }}
                          onText='Active Product'
                        />
                        <br />
                        <Switch
                          formText='Whether or not the product is a "custom" product.'
                          isChecked={productDetails.isCustomProduct || false}
                          offText='NOT A Custom Product'
                          onChange={() => {
                            setProductDetails((previous) => {
                              return {
                                ...productDetails,
                                isCustomProduct: !previous?.isCustomProduct
                              }
                            })
                          }}
                          onText='A Custom Product'
                        />
                      </>
                    </GridItem>
                  </Grid>
                </>
              </Tab>
              <Tab tabKey='marketplace' title='Marketplace'>
                <>
                  <Grid>
                    <GridItem colSpan={8}></GridItem>
                    <GridItem colSpan={4}>
                      <SelectProductApprovalStatus
                        defaultValue={productDetails.approvalStatus}
                        onSelected={(selectedOption) => {
                          setProductDetails({
                            ...productDetails,
                            approvalStatus: selectedOption
                          })
                        }}
                      />
                      <Switch
                        formText='Manually determine if a product should be shown in the Marketplace.'
                        isChecked={productDetails.showInStore || false}
                        offText='Hidden From Store'
                        onChange={() => {
                          setProductDetails((previous) => {
                            return {
                              ...productDetails,
                              showInStore: !previous?.showInStore
                            }
                          })
                        }}
                        onText='Shown In Store'
                      />
                    </GridItem>
                  </Grid>
                </>
              </Tab>
              <Tab tabKey='fulfillment' title='Fulfillment'>
                <Grid>
                  <GridItem colSpan={8}>
                    <>
                      <InputNumber
                        formText='How many days the supplier needs to fulfill an order for this product.'
                        label='Days Needed To Fulfill'
                        labelRequired={false}
                        onChange={(_newValue, numberValue) => {
                          setProductDetails({
                            ...productDetails,
                            daysToFulfill: numberValue
                          })
                        }}
                        value={productDetails.daysToFulfill?.toString() || ''}
                      />
                      <InputNumber
                        decimalPlaces={5}
                        formText='An additional fee that gets added during checkout for hazmat processing.'
                        label='Hazmat Fee'
                        labelRequired={false}
                        onChange={(_newValue, numberValue) => {
                          setProductDetails({
                            ...productDetails,
                            hazmatFee: numberValue
                          })
                        }}
                        prepend='$'
                        value={productDetails.hazmatFee?.toString() || ''}
                      />
                      <InputNumber
                        decimalPlaces={5}
                        formText='Will provide a flat shipping cost to users during checkout.'
                        label='Flat Shipping Cost'
                        labelRequired={false}
                        onChange={(_newValue, numberValue) => {
                          setProductDetails({
                            ...productDetails,
                            flatShippingCost: numberValue
                          })
                        }}
                        prepend='$'
                        value={productDetails.flatShippingCost?.toString() || ''}
                      />
                    </>
                  </GridItem>
                  <GridItem colSpan={4}>
                    <>
                      <Switch
                        formText='Whether or not this product is shipped by a supplier.'
                        isChecked={productDetails.isDroppedShipped || false}
                        offText='Non Drop Shipped Item'
                        onChange={() => {
                          setProductDetails((previous) => {
                            return {
                              ...productDetails,
                              isDroppedShipped: !previous?.isDroppedShipped
                            }
                          })
                        }}
                        onText='Drop Shipped Item'
                      />
                      <br />
                      <Switch
                        formText='Whether or not this product is "shippable".  Useful for "service" based products.'
                        isChecked={productDetails.isShippable || false}
                        offText='Non Shippable Product'
                        onChange={() => {
                          setProductDetails((previous) => {
                            return {
                              ...productDetails,
                              isShippable: !previous?.isShippable
                            }
                          })
                        }}
                        onText='Shippable Product'
                      />
                      <br />
                      <Switch
                        formText='If enabled, product can not be cancelled once ordered.'
                        isChecked={productDetails.isNonCancellable || false}
                        offText='Product Can Be Cancelled'
                        onChange={() => {
                          setProductDetails((previous) => {
                            return {
                              ...productDetails,
                              isNonCancellable: !previous?.isNonCancellable
                            }
                          })
                        }}
                        onText='Product Can NOT Be Cancelled'
                      />
                      <br />
                      <Switch
                        formText='If enabled, product can not be returned once delivered.'
                        isChecked={productDetails.isNonReturnable || false}
                        offText='Product Can Be Returned'
                        onChange={() => {
                          setProductDetails((previous) => {
                            return {
                              ...productDetails,
                              isNonReturnable: !previous?.isNonReturnable
                            }
                          })
                        }}
                        onText='Product Can NOT Be Returned'
                      />
                      <br />
                      <Switch
                        formText='If enabled, "ground" shipping will always be free.'
                        isChecked={productDetails.freeShipping || false}
                        offText='Free Shipping NOT Enabled'
                        onChange={() => {
                          setProductDetails((previous) => {
                            return {
                              ...productDetails,
                              freeShipping: !previous?.freeShipping
                            }
                          })
                        }}
                        onText='Free Shipping Is Enabled'
                      />
                      <br />
                      <Switch
                        formText='If enabled, no parcel shipment estimates will be run and the product will always ship as freight.'
                        isChecked={productDetails.freightShippingRequired || false}
                        offText='Freight Shipping NOT Required'
                        onChange={() => {
                          setProductDetails((previous) => {
                            return {
                              ...productDetails,
                              freightShippingRequired: !previous?.freightShippingRequired
                            }
                          })
                        }}
                        onText='Freight Shipping Is Required'
                      />
                    </>
                  </GridItem>
                </Grid>
              </Tab>
              <Tab tabKey='inventory' title='Inventory'>
                <>
                  <Grid>
                    <GridItem colSpan={8}>
                      <InputDateTime
                        formText='The date the product is estimated to be back in stock.'
                        label='Date Estimated Back In Stock'
                        onChange={(newDate) => {
                          setProductDetails({
                            ...productDetails,
                            dateEstimatedBackInStock: newDate?.toISOString() || undefined
                          })
                        }}
                        value={
                          productDetails.dateEstimatedBackInStock
                            ? new Date(productDetails.dateEstimatedBackInStock)
                            : null
                        }
                      />
                    </GridItem>
                    <GridItem colSpan={4}>
                      <Switch
                        formText='An out of stock item can not be purchased.'
                        isChecked={!productDetails.isOutOfStock}
                        offText='Out Of Stock'
                        onChange={() => {
                          setProductDetails((previous) => {
                            return {
                              ...productDetails,
                              isOutOfStock: !previous?.isOutOfStock
                            }
                          })
                        }}
                        onText='In Stock'
                      />
                    </GridItem>
                  </Grid>
                </>
              </Tab>
              <Tab tabKey='images' title='Images'>
                <>
                  <Grid cols={2}>
                    <GridItem colSpan={1}>
                      <Card>
                        <CardHeader
                          actions={
                            <CardHeaderActions>
                              <Button
                                onClick={() => {
                                  setModalProductImageOpen(true)
                                }}
                              >
                                <FontAwesomeIcon icon={faCamera} />
                                &nbsp;Change Product Image
                              </Button>
                            </CardHeaderActions>
                          }
                          title={<CardHeaderTitle>Primary Image</CardHeaderTitle>}
                        />
                        <CardBody>
                          <div style={{ textAlign: 'center' }}>
                            <img
                              alt='Product'
                              className='max-width-100 border'
                              src={productDetails.image || theme.images.notAvailable}
                            />
                          </div>
                        </CardBody>
                      </Card>
                    </GridItem>
                    <GridItem colSpan={1}>
                      <Card>
                        <CardHeader title={<CardHeaderTitle>Additional Images</CardHeaderTitle>} />
                        <CardBody>
                          <NotDone />
                        </CardBody>
                      </Card>
                    </GridItem>
                  </Grid>
                </>
              </Tab>
              <Tab tabKey='pricing' title='Cost & Pricing'>
                <>
                  <Tabs tabs2>
                    <Tab tabKey='cost' title='Costs'>
                      <>
                        <InputNumber
                          decimalPlaces={5}
                          formText='The suppliers suggested retail price.'
                          label='MSRP'
                          labelRequired={false}
                          onChange={(_newValue, numberValue) => {
                            setProductDetails({
                              ...productDetails,
                              msrp: numberValue
                            })
                          }}
                          prepend='$'
                          value={productDetails.msrp?.toString() || ''}
                        />
                        <Grid cols={3}>
                          <GridItem colSpan={1}>
                            <>
                              <InputCurrency
                                decimalPlaces={5}
                                formText='The amount MainStem will pay the supplier when purchased in a retail price list.'
                                label='Retail Cost'
                                labelRequired={true}
                                onChange={(newValue) => {
                                  setNewPurchasePriceRetail(newValue)
                                }}
                                prepend='$'
                                value={newPurchasePriceRetail}
                              />
                              <hr />
                              <InputCurrency
                                decimalPlaces={5}
                                disabled={true}
                                formText='The amount a customer will pay the MainStem when purchased in a retail price list.'
                                label='Retail Price'
                                labelRequired={true}
                                onChange={() => {}}
                                prepend='$'
                                value={productDetails.priceSellRetail?.toString() || ''}
                              />
                              <small>
                                Sell Price Calculation: ({newPurchasePriceRetail} / (1 -{' '}
                                {productDetails.defaultProfitMarginRetail ||
                                  productDetails.supplier?.defaultProfitMarginRetail}
                                % / 100))
                                <br />
                                <i>
                                  {productDetails.defaultProfitMarginRetail
                                    ? "Using Product's Margin"
                                    : "Using Supplier's Margin"}
                                </i>
                              </small>
                            </>
                          </GridItem>
                          <GridItem colSpan={1}>
                            <>
                              <InputCurrency
                                decimalPlaces={5}
                                formText='The amount MainStem will pay the supplier when purchased in a wholesale price list.'
                                label='Wholesale Cost'
                                labelRequired={true}
                                onChange={(newValue) => {
                                  setNewPurchasePriceWholesale(newValue)
                                }}
                                prepend='$'
                                value={newPurchasePriceWholesale}
                              />
                              <hr />
                              <InputCurrency
                                decimalPlaces={5}
                                disabled={true}
                                formText='The amount a customer will pay the MainStem when purchased in a wholesale price list.'
                                label='Wholesale Price'
                                labelRequired={true}
                                onChange={() => {}}
                                prepend='$'
                                value={productDetails.priceSellWholesale?.toString() || ''}
                              />
                              <small>
                                Sell Price Calculation: ({newPurchasePriceWholesale} / (1 -{' '}
                                {productDetails.defaultProfitMarginWholesale ||
                                  productDetails.supplier?.defaultProfitMarginWholesale}
                                % / 100))
                                <br />
                                <i>
                                  {productDetails.defaultProfitMarginWholesale
                                    ? "Using Product's Margin"
                                    : "Using Supplier's Margin"}
                                </i>
                              </small>
                            </>
                          </GridItem>
                          <GridItem colSpan={1}>
                            <>
                              <InputCurrency
                                decimalPlaces={5}
                                formText='The amount MainStem will pay the supplier when purchased in enterprise retail price list.'
                                label='Enterprise Cost'
                                labelRequired={true}
                                onChange={(newValue) => {
                                  setNewPurchasePriceEnterprise(newValue)
                                }}
                                prepend='$'
                                value={newPurchasePriceEnterprise}
                              />
                              <hr />
                              <InputCurrency
                                decimalPlaces={5}
                                disabled={true}
                                formText='The amount a customer will pay the MainStem when purchased in a enterprise price list.'
                                label='Enterprise Price'
                                labelRequired={true}
                                onChange={() => {}}
                                prepend='$'
                                value={productDetails.priceSellEnterprise?.toString() || ''}
                              />
                              <small>
                                Sell Price Calculation: ({newPurchasePriceEnterprise} / (1 -{' '}
                                {productDetails.defaultProfitMarginEnterprise ||
                                  productDetails.supplier?.defaultProfitMarginEnterprise}
                                % / 100))
                                <br />
                                <i>
                                  {productDetails.defaultProfitMarginEnterprise
                                    ? "Using Product's Margin"
                                    : "Using Supplier's Margin"}
                                </i>
                              </small>
                            </>
                          </GridItem>
                        </Grid>
                      </>
                    </Tab>
                    <Tab tabKey='margin' title='Margins'>
                      <>
                        <Grid cols={3}>
                          <GridItem colSpan={1}>
                            <InputNumber
                              append={'%'}
                              label='Retail Margin'
                              labelRequired={false}
                              onChange={(_newValue, numberValue) => {
                                setProductDetails({
                                  ...productDetails,
                                  defaultProfitMarginRetail: numberValue
                                })
                              }}
                              value={productDetails.defaultProfitMarginRetail?.toString() || ''}
                            />
                          </GridItem>
                          <GridItem colSpan={1}>
                            <InputNumber
                              append={'%'}
                              label='Wholesale Margin'
                              labelRequired={false}
                              onChange={(_newValue, numberValue) => {
                                setProductDetails({
                                  ...productDetails,
                                  defaultProfitMarginWholesale: numberValue
                                })
                              }}
                              value={productDetails.defaultProfitMarginWholesale?.toString() || ''}
                            />
                          </GridItem>
                          <GridItem colSpan={1}>
                            <InputNumber
                              append={'%'}
                              label='Enterprise Margin'
                              labelRequired={false}
                              onChange={(_newValue, numberValue) => {
                                setProductDetails({
                                  ...productDetails,
                                  defaultProfitMarginEnterprise: numberValue
                                })
                              }}
                              value={productDetails.defaultProfitMarginEnterprise?.toString() || ''}
                            />
                          </GridItem>
                        </Grid>
                        <br />
                        <Grid cols={2}>
                          <GridItem colSpan={1}>
                            <InputNumber
                              append={'%'}
                              label='Volume Pricing Margin'
                              labelRequired={false}
                              onChange={(_newValue, numberValue) => {
                                setProductDetails({
                                  ...productDetails,
                                  defaultProfitMarginVolumePricing: numberValue
                                })
                              }}
                              value={productDetails.defaultProfitMarginVolumePricing?.toString() || ''}
                            />
                          </GridItem>
                          <GridItem colSpan={1}>
                            <InputNumber
                              append={'%'}
                              label='Price Agreement Margin'
                              labelRequired={false}
                              onChange={(_newValue, numberValue) => {
                                setProductDetails({
                                  ...productDetails,
                                  defaultProfitMarginPriceAgreements: numberValue
                                })
                              }}
                              value={productDetails.defaultProfitMarginPriceAgreements?.toString() || ''}
                            />
                          </GridItem>
                        </Grid>
                      </>
                    </Tab>
                    <Tab tabKey='volume' title='Volume Pricing'>
                      <>
                        <ListItemStat
                          title={
                            <>
                              Default Volume Pricing Margin
                              <br />
                              <i style={{ fontSize: 10, fontWeight: 500 }}>
                                Margin Set Via:{' '}
                                {productDetails.defaultProfitMarginVolumePricing ? 'Product' : 'Supplier'}
                                &nbsp;
                                <MoreInfo
                                  text={
                                    productDetails.defaultProfitMarginVolumePricing
                                      ? 'We are using the default volume pricing margin set explicitly on this product.'
                                      : 'There is no default volume pricing margin set explicitly on this product so we are using the default volume pricing margin set on the supplier.'
                                  }
                                />
                              </i>
                            </>
                          }
                        >
                          <FormattedNumber
                            decimalPlaces={5}
                            value={
                              productDetails.defaultProfitMarginVolumePricing ||
                              productDetails.supplier?.defaultProfitMarginVolumePricing ||
                              0
                            }
                          />
                          %
                        </ListItemStat>
                        <Switch
                          formText='Whether or not the product allows volume pricing.'
                          isChecked={productDetails.hasVolumePricing || false}
                          offText='Volume Pricing Disabled'
                          onChange={() => {
                            setProductDetails((previous) => {
                              return {
                                ...productDetails,
                                hasVolumePricing: !previous?.hasVolumePricing
                              }
                            })
                          }}
                          onText='Volume Pricing Enabled'
                        />
                        <br />
                        {productDetails.id && (
                          <ProductDetailsVolumePricing
                            defaultMargin={
                              productDetails.defaultProfitMarginVolumePricing ||
                              productDetails.supplier?.defaultProfitMarginVolumePricing ||
                              0
                            }
                            editable
                            onUpdated={loadProductDetails}
                            productId={productDetails.id}
                            volumePricing={volumePricing}
                          />
                        )}
                      </>
                    </Tab>
                    <Tab tabKey='agreements' title='Price Agreements'>
                      <>
                        <ListItemStat
                          title={
                            <>
                              Default Price Agreement Margin
                              <br />
                              <i style={{ fontSize: 10, fontWeight: 500 }}>
                                Margin Set Via:{' '}
                                {productDetails.defaultProfitMarginPriceAgreements ? 'Product' : 'Supplier'}
                                &nbsp;
                                <MoreInfo
                                  text={
                                    productDetails.defaultProfitMarginPriceAgreements
                                      ? 'We are using the default price agreement margin set explicitly on this product.'
                                      : 'There is no default price agreement margin set explicitly on this product so we are using the default price agreement margin set on the supplier.'
                                  }
                                />
                              </i>
                            </>
                          }
                        >
                          <FormattedNumber
                            decimalPlaces={5}
                            value={
                              productDetails.defaultProfitMarginPriceAgreements ||
                              productDetails.supplier?.defaultProfitMarginPriceAgreements ||
                              0
                            }
                          />
                          %
                        </ListItemStat>
                        {productDetails.priceAgreements && productDetails.id && (
                          <PriceAgreements
                            defaultCost={productDetails.pricePurchaseRetail}
                            defaultMargin={
                              productDetails.defaultProfitMarginPriceAgreements ||
                              productDetails.supplier?.defaultProfitMarginPriceAgreements
                            }
                            onUpdated={() => {
                              loadProductDetails()
                            }}
                            priceAgreements={productDetails.priceAgreements}
                            productId={productDetails.id}
                          />
                        )}
                      </>
                    </Tab>
                  </Tabs>
                </>
              </Tab>
              <Tab tabKey='dimensions' title='Dimensions'>
                <>
                  <Grid cols={3}>
                    <GridItem colSpan={1}>
                      <InputNumber
                        label='Weight (lb)'
                        labelRequired={true}
                        onChange={(_newValue, numberValue) => {
                          setProductDetails({
                            ...productDetails,
                            weight: numberValue
                          })
                        }}
                        value={productDetails.weight?.toString() || ''}
                      />
                    </GridItem>
                    <GridItem colSpan={1}>
                      <InputText
                        label='Unit of Measure - Value'
                        labelRequired={true}
                        onChange={(newValue) => {
                          setProductDetails({
                            ...productDetails,
                            unitOfMeasureValue: newValue
                          })
                        }}
                        value={productDetails.unitOfMeasureValue || ''}
                      />
                    </GridItem>
                    <GridItem colSpan={1}>
                      <MainStemSelectProductUnitOfMeasureLabel
                        creatable={true}
                        defaultValue={productDetails.unitOfMeasureLabel}
                        label='Unit of Measure - Label'
                        labelRequired={true}
                        onChange={(selectedOption) => {
                          setSelectedProductUnitOfMeasureLabel(selectedOption)
                          setProductDetails({
                            ...productDetails,
                            unitOfMeasureLabel: selectedOption?.value
                          })
                        }}
                        onCreateOption={(newOption) => {
                          setProductDetails({
                            ...productDetails,
                            unitOfMeasureLabel: newOption
                          })
                          setSelectedProductUnitOfMeasureLabel({ label: newOption, value: newOption })
                        }}
                        value={selectedProductUnitOfMeasureLabel}
                      />
                    </GridItem>
                  </Grid>
                  <br />
                  <Grid cols={3}>
                    <GridItem colSpan={1}>
                      <InputNumber
                        label='Height (in)'
                        labelRequired={false}
                        onChange={(_newValue, numberValue) => {
                          setProductDetails({
                            ...productDetails,
                            height: numberValue
                          })
                        }}
                        value={productDetails.height?.toString() || ''}
                      />
                    </GridItem>
                    <GridItem colSpan={1}>
                      <InputNumber
                        label='Width (in)'
                        labelRequired={false}
                        onChange={(_newValue, numberValue) => {
                          setProductDetails({
                            ...productDetails,
                            width: numberValue
                          })
                        }}
                        value={productDetails.width?.toString() || ''}
                      />
                    </GridItem>
                    <GridItem colSpan={1}>
                      <InputNumber
                        label='Length (in)'
                        labelRequired={false}
                        onChange={(_newValue, numberValue) => {
                          setProductDetails({
                            ...productDetails,
                            length: numberValue
                          })
                        }}
                        value={productDetails.length?.toString() || ''}
                      />
                    </GridItem>
                  </Grid>
                </>
              </Tab>
              <Tab tabKey='limits' title='Ordering Limits'>
                <>
                  <Grid cols={2}>
                    <GridItem colSpan={1}>
                      <InputNumber
                        formText='The minimum amount someone needs to order for this product.'
                        label='Minimum Purchasable Quantity'
                        labelRequired={false}
                        onChange={(_newValue, numberValue) => {
                          setProductDetails({
                            ...productDetails,
                            minimumPurchasableQuantity: numberValue
                          })
                        }}
                        value={productDetails.minimumPurchasableQuantity?.toString() || ''}
                      />
                    </GridItem>
                    <GridItem colSpan={1}>
                      <InputNumber
                        formText='The maximum amount someone is allowed to order for this product.'
                        label='Maximum Purchasable Quantity'
                        labelRequired={false}
                        onChange={(_newValue, numberValue) => {
                          setProductDetails({
                            ...productDetails,
                            maximumPurchasableQuantity: numberValue
                          })
                        }}
                        value={productDetails.maximumPurchasableQuantity?.toString() || ''}
                      />
                    </GridItem>
                  </Grid>
                  <Grid cols={2}>
                    <GridItem colSpan={1}>
                      <InputNumber
                        formText='The amount of items the unit must be purchased of.'
                        label='Sold In Quantities Of'
                        labelRequired={false}
                        onChange={(_newValue, numberValue) => {
                          setProductDetails({
                            ...productDetails,
                            soldInQuantitiesOf: numberValue
                          })
                        }}
                        value={productDetails.soldInQuantitiesOf?.toString() || ''}
                      />
                    </GridItem>
                  </Grid>
                </>
              </Tab>
              <Tab tabKey='attributes' title='Attributes'>
                <>
                  <ProductDetailsAttributes
                    attributes={attributeValues}
                    editable
                    onUpdated={loadProductDetails}
                    productId={productDetails.id}
                    setAttributes={(value) => {
                      setProductDetails({
                        ...productDetails,
                        attributeValues: value
                      })
                    }}
                  />
                </>
              </Tab>
              <Tab tabKey='tags' title='Tags'>
                <>
                  <ProductDetailsTags
                    onUpdated={loadProductDetails}
                    productId={productDetails.id}
                    productsTags={productDetails.tags}
                    setProductTags={(value) => {
                      setProductDetails({ ...productDetails, tags: value })
                    }}
                  />
                </>
              </Tab>
              <Tab tabKey='taxes' title='Taxes'>
                <>
                  <Grid>
                    <GridItem colSpan={4}>
                      <>
                        <Switch
                          formText='If enabled, sales tax will never be calculated for this product.'
                          isChecked={productDetails.taxExempt || false}
                          onChange={() => {
                            setProductDetails((previous) => {
                              return {
                                ...productDetails,
                                taxExempt: !previous?.taxExempt
                              }
                            })
                          }}
                        >
                          Tax Exempt
                        </Switch>
                      </>
                    </GridItem>
                    <GridItem colSpan={8}>
                      <InputText
                        formText={'A tax code is recommended for more accurate sales tax estimations.'}
                        label='Tax Code'
                        labelRequired={true}
                        onChange={(newValue) => {
                          setProductDetails({
                            ...productDetails,
                            taxCode: newValue
                          })
                        }}
                        placeholder='Example: PS160751'
                        value={productDetails.taxCode || ''}
                      />
                      <a href='https://taxcode.avatax.avalara.com/' target='blank'>
                        Avalara Tax Code Mapping
                      </a>
                    </GridItem>
                  </Grid>
                </>
              </Tab>
              <Tab tabKey='customs' title='Customs'>
                <InputText
                  label='Harmonized Tarrif Codes'
                  labelRequired={false}
                  onChange={(newValue) => {
                    setProductDetails({
                      ...productDetails,
                      customsHarmonizedTarrifCodes: newValue
                    })
                  }}
                  value={productDetails.customsHarmonizedTarrifCodes || ''}
                />
                <TextArea
                  label='Customs Description'
                  labelRequired={false}
                  onChange={(newValue) => {
                    setProductDetails({
                      ...productDetails,
                      customsDescription: newValue
                    })
                  }}
                  value={productDetails.customsDescription || ''}
                />
              </Tab>
              <Tab tabKey='USNSPC' title='USNSPC'>
                <>
                  <NotDone />
                </>
              </Tab>
              <Tab tabKey='qa' title='Q & A'>
                <>
                  <NotDone />
                </>
              </Tab>
              <Tab tabKey='quickBooks' title='QuickBooks'>
                <>
                  <InputText
                    formText='This is the ID of the QuickBooks item in MainStems own QuickBooks Online company file.'
                    label='QuickBooks ID'
                    labelRequired={false}
                    onChange={(newValue) => {
                      setProductDetails({
                        ...productDetails,
                        quickBooksId: newValue
                      })
                    }}
                    value={productDetails.quickBooksId || ''}
                  />
                </>
              </Tab>
            </Tabs>
          </CardBody>
        </Card>
      </ProfileWrapper>
      {modalProductImageOpen && (
        <ProductDetailsProductImage
          isOpen={modalProductImageOpen}
          onClose={() => {
            setModalProductImageOpen(false)
          }}
          onUpdated={loadProductDetails}
          productId={productDetails.id}
        />
      )}
    </>
  )
}

export default PageProductChildUpdate
