// React
import { useState } from 'react'
// Routing
import { useHistory } from 'react-router-dom'
// MainStem - UI
import {
  Button,
  Card,
  CardBody,
  CardHeader,
  CardHeaderSubtitle,
  CardHeaderTitle,
  Grid,
  GridItem,
  InputText
} from '@mainstem/react-mainstem'
// MainStem - UI - Types
import { ISelectOption } from '@mainstem/react-mainstem/dist/components/Form/Select/types'
// Icons
import { faChevronLeft, faChevronRight } from '@fortawesome/pro-light-svg-icons'
// Global - Components
import { CenterPage, SelectParentProduct, SelectSupplier } from 'components'

const PageProductChildCreate: React.FC = () => {
  // Routing
  const history = useHistory()
  // View State
  const [name, setName] = useState<string>('')
  const [selectedParentProduct, setSelectedParentProduct] = useState<undefined | ISelectOption>(undefined)
  const [selectedSupplier, setSelectedSupplier] = useState<undefined | ISelectOption>(undefined)
  const [subTitle, setSubTitle] = useState<string>('Enter details for your new child product.')
  const [title, setTitle] = useState<string>('Create A New Child Product')

  const handleCreateProduct = () => {
    // TODO: Work
  }

  return (
    <>
      <CenterPage>
        <Card>
          <CardHeader
            subtitle={<CardHeaderSubtitle>{subTitle}</CardHeaderSubtitle>}
            title={<CardHeaderTitle>{title}</CardHeaderTitle>}
          />
          <CardBody>
            <>
              <InputText
                label={'Product Name'}
                labelRequired={true}
                onChange={(newValue) => {
                  setName(newValue)
                }}
                value={name}
              />
              <SelectParentProduct
                onSelected={(selectedOption) => {
                  setSelectedParentProduct(selectedOption)
                }}
              />
              <br />
              <SelectSupplier
                onSelected={(selectedOption) => {
                  setSelectedSupplier(selectedOption)
                }}
              />
              <hr />
              <Grid cols={2}>
                <GridItem colSpan={1}>
                  <Button
                    block={true}
                    color='danger'
                    icon={faChevronLeft}
                    onClick={() => {
                      history.push(`/products/create`)
                    }}
                  >
                    Go Back
                  </Button>
                </GridItem>
                <GridItem colSpan={1}>
                  <Button block={true} color='fancy' icon={faChevronRight} onClick={handleCreateProduct}>
                    Next
                  </Button>
                </GridItem>
              </Grid>
            </>
          </CardBody>
        </Card>
      </CenterPage>
    </>
  )
}

export default PageProductChildCreate
