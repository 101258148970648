import React, { Fragment, useState, useCallback, useEffect } from 'react'
import ModalShipmentTracking from 'components/Modal/ModalShipmentTracking'
import { faPlus } from '@fortawesome/pro-regular-svg-icons'
import { apiShipmentTrackingOfInvoice } from 'api/shipment-tracking/shipment-tracking-for-invoice'
import { PrettyTable } from '@mainstem/mainstem-react-app'
import { Button } from '@mainstem/react-mainstem'
import { shipmentTrackingColumns, shipmentTrackingOptions } from './tables/table-shipment-tracking'

// import { ShipmentTrackingInvoiceList } from 'components/ShipmentTrackingInvoiceList'
/* 
    <ShipmentTrackingInvoiceList
      invoiceID={row.fulfillmentId}
      onActionApplied={() => {
        loadSingleRow(row, rowIndex)
      }}
    />
*/

/* =============================================================================
                          ShipmentTrackingInvoiceList
============================================================================= */

export const ShipmentTrackingInvoiceList = ({ invoiceID, onActionApplied }) => {
  const [firstLoad, setFirstLoad] = useState(false)
  const [shipmentTracking, setShipmentTracking] = useState([])
  const [showTrackingModal, setShowTrackingModal] = useState(false)
  const [selectedShipmentTracking, setSelectedShipmentTracking] = useState(null)
  const [loading, setLoading] = useState(true)

  /* ======================
  loadShipmentTrackingForInvoice()
  ====================== */

  const loadShipmentTrackingForInvoice = useCallback(() => {
    const apiRequest = {
      invoiceID: invoiceID
    }
    setLoading(true)
    apiShipmentTrackingOfInvoice(apiRequest).then((response) => {
      if (response.wasSuccessful) {
        setShipmentTracking(response.shipmentTracking)
      }
      setShowTrackingModal(false)
      setLoading(false)
    })
  }, [invoiceID])

  /* ======================
      handleEditClicked()
  ====================== */

  const handleEditClicked = (trackingDetails) => {
    setSelectedShipmentTracking(trackingDetails)
    setShowTrackingModal(true)
  }

  /* ======================
        useEffect()
  ====================== */

  useEffect(() => {
    if (firstLoad) return
    setFirstLoad(true)
    loadShipmentTrackingForInvoice()
  }, [firstLoad, loadShipmentTrackingForInvoice])

  /* ======================
          return
  ====================== */

  return (
    <Fragment>
      <PrettyTable
        columns={shipmentTrackingColumns({
          onEditClickedFn: handleEditClicked
        })}
        customButtons={
          <Fragment>
            <Button
              icon={faPlus}
              onClick={() => {
                setSelectedShipmentTracking(null)
                setShowTrackingModal(true)
              }}
            >
              Add Shipment Tracking
            </Button>
          </Fragment>
        }
        data={shipmentTracking}
        loading={loading}
        options={shipmentTrackingOptions}
        title='Shipment Tracking - Invoice'
      />

      {showTrackingModal && (
        <ModalShipmentTracking
          invoiceID={invoiceID}
          onClose={() => {
            setShowTrackingModal(false)
            setSelectedShipmentTracking(null)
          }}
          onSuccess={() => {
            if (typeof onActionApplied === 'function') {
              onActionApplied()
            }
            loadShipmentTrackingForInvoice()
          }}
          shipmentTracking={selectedShipmentTracking}
        />
      )}
    </Fragment>
  )
}
